import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { UserContext } from '../../contexts/userContext';

const Step1: React.FC = () => {
  const { setData, data } = useWizard();
  const { user } = useContext(UserContext);
  const [profession, setProfession] = useState(data.profession);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const premium = user.id;

  useEffect(() => {
    setData({ ...data, profession, name, lastname: lastName, email, phone });
  }, [profession, name, lastName, email, phone]);
  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>Utilizamos standard DISC para aproximar las preferencias y comportamientos del usuario.</Typography>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>Escribe tu profesión en el siguiente campo.</Typography>
      <TextField
        label="Tu profesión"
        value={profession}
        onChange={(e) => setProfession(e.target.value)}
        fullWidth />
      <Box>
        <a href="https://idtalento.com/prueba-disc-estilos-de-personalidad/" target="_blank" rel="noreferrer">
          <Typography variant="body1">
          Querés saber más sobre DISC (hacer blog)
          </Typography>
        </a>
      </Box>
      {premium ? (
        <Box>
          <Typography variant="h6" sx={{ marginBottom: 2, mt: 2 }}>Completá los datos personales</Typography>
          <Box mt={2} display={['flex']} flexDirection={['column', 'row']} gap={2}>
            <TextField
              label="Nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
            <TextField
              label="Apellido"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
            />
          </Box>
          <Box mt={2} display={['flex']} flexDirection={['column']} gap={2} >
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
            <TextField
              label="Teléfono"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
            />
          </Box>
        </Box>
      ) : (<></>)}
    </Box>
  );
};

export default Step1;