import React, { useState } from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import api, { Exercise } from '../../api';
import { useWizard } from '../../contexts/PlanifyWizardContext';

type Props = {
  day: number;
  onAdd: (day: number, data: Exercise) => void;
};

const AddExerciseButton: React.FC<Props> = ({ day, onAdd }) => {
  const { result, setResult } = useWizard();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleAddExercise = async () => {
    if (!result) return;
    setLoading(true);
    setError(null);
    try {
      const exercises = result.planification[day].exercises;
      const user = result.user;
      const data = { exercises, user };
      const res = await api.openai.generateExercise(JSON.stringify(data));
      if (res.success && res.data) {
        //result.planification[day].exercises.push({ ...res.data, id: exercises.length });
        //setResult({ ...result });
        onAdd(day, res.data);
      }
      // Handle successful response
    } catch (error: any) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box mt={2} width={"100%"} textAlign={"center"}>
      <Button variant="contained" startIcon={<AddOutlined />} onClick={handleAddExercise} disabled={loading}>
        Agregar ejercicio con asistente
        {loading && <CircularProgress size={20}  sx={{ ml: 2 }}/>}
      </Button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </Box>
  );
};

export default AddExerciseButton;