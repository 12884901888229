import dayjs from "dayjs";

export const formatToLongDate = (date: any) => {
  const d = new Date(date);
  return d.toLocaleDateString('es-ar', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) 
}
export const formatBirthday = (date: any) => {
  const birthday = dayjs(date);
  const age = dayjs().diff(birthday, 'years');
  if (age > 4) {
    return `${birthday.format('DD/MM/YYYY')} (${age} años)`;  
  } else {
    return '-';
  }
}

export const formatDate = (date: any) => {
  return dayjs(date).format('DD/MM/YYYY');
}
