import React, { ReactElement, FC, useEffect, useState, useContext } from "react";
import { Box, Button,  List, ListItemButton, ListItemAvatar, ListItemText, Typography, SpeedDial, SpeedDialIcon, Divider, ListItemSecondaryAction } from "@mui/material";
import api, { Conversation, TrainingClass } from "../api";
import { Link, useNavigate } from "react-router-dom";
import { ClassOutlined, Psychology, Visibility, VisibilityOff } from "@mui/icons-material";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import dayjs from "dayjs";

type ConversationsPageState = {
  page: number,
  error?: string
  status: string
}

const ConversationsPage: FC<any> = (): ReactElement => {
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const { user } = useContext(UserContext);
  const [request, setRequest] = useState<ConversationsPageState>({ page: 0, status: 'initial'});
  const PAGE_SIZE = 10;
  const navigate = useNavigate();


  const getNextConversationsPage = async () => {
    setRequest({ page: request.page + 1, status: "loading" })
    const res = await api.conversations.get(request.page, PAGE_SIZE);
    if (res.success) {
      setConversations(conversations.concat(res.data));
      if (res.data?.length) {
        setRequest((values) => ({ ...values, status: 'success' }));
      }else {
        setRequest((values) => ({ ...values, status: 'end' }));
      }
    }
  }

  const handleClick = (p: Conversation) => () => {
    navigate(`/conversations/${p.id}`);
  }

  useEffect(() => {
    getNextConversationsPage();
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
    >

      <PageTitle title="Conversaciones" />
      <List
        sx={{ bgcolor: 'background.paper', paddingBottom: '70px' }}
        component="nav"
      >
        {conversations.map((p: Conversation) => (
          <ListItemButton key={p.id} onClick={handleClick(p)}>
            <ListItemText
              primary={p.user.name}
              secondary={dayjs(p.aiMessages[0].createdAt).format('DD/MM/YYYY HH:mm')}
            />
            <Divider />
          </ListItemButton>
        ))}
      </List>
      {request.status !== 'end' && (<Button variant="contained" onClick={getNextConversationsPage}>Ver más</Button>)}
    </Box>
  );
};

export default ConversationsPage;