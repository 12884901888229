import { ClientUpdate } from '../components/client/edit-form';
import { extractExerciseFromText, extractWorkoutWeekFromText } from '../utils/json.extract';
import axios from './axios-instance';
import dayjs from 'dayjs';

export type User = {
  name: string,
  lastname: string,
  birthdate?: string,
  email: string,
  phone?: string,
  gender: string,

  document?: string,
};
export type Band = {
  id: number;
  code: string;
};
export type ClientHealth = {
  id: number;
  age: number;
  weight: number;
  height: number;
  basal: number;
  max: number;
  clientId: number;
  createdAt: Date;
  injuries: string;
  previousExperience: string;
}
export type Client = {
  id: number;
  nick: string;
  userId: number;
  clientHealths: ClientHealth[];
  clientGoals: ClientGoal[];
  profession: string;
  bodyType: string;
  user?: User;
}
export type Professor = {
  id: number;
  userId: number;
  pin: string;
  user: User;
}
export type ProgramStep = {   
  id?: number;
  name: string;
  description?: string;
  efforts: number;
  duration: number;
  programId?: number; 
};
export type Program = {
  id?: number;
  name: string;
  description: string;
  authorId: number;
  author?: Professor;
  totalTime?: number;
  steps: ProgramStep[];
};

export type TrainingClass = {
  id: number;
  name: string;
  startTime: Date;
  duration: number;
  professorId: number;
  programId: number;
  roomId: number;

  program: Program;
  room: Room;
  professor: Professor;
  hidden: boolean;
};

export type TrainingSession = {
  id: number;
  name: string;
  professorId: number;
  bandId: number;
  tClassId: number;
  status: String;
  currentData: number[];
  currentEffort: number[];
  calories: number;
  points: number;
  avgEffort: number;
  autologout: boolean;
  createdAt: Date;
  clientId: number;
  clientHealthId: number;

  band: Band;

  tClass: TrainingClass;
  client: Client;
  clientHealth: ClientHealth;
  professor: Professor;
}

type SimpleTraining = {
  id: number;
  name: string;
};

export type Room = {
  id: number
  name: string
  code: string
  avatar: string | null
  parent: string | null
  status: String
  trainings?: SimpleTraining[]
}

export type RankingElement = {
  id: string
  trainingId: number,
  position: number
  instantPoints: string
  currentEffort: string
  points: string
  nick: string
  lastBPM: string
  calories: string
  avatarUrl: string
}

export type PlanificationUser = {
  name: string
  lastname: string
  gender? : string
  birthdate?: string
  age?: number
  weight?: number
  height?: number
  basal_hr?: number
  exercisePreference: string
  previousExperience: string
  mainGoal: string
  availabilityDaysPerWeek?: number
  timePerSession: string
  previousInjuries: string
  profession: string
  bodytype?: string,
  plan?: any,
  email?: string,
  phone?: string,
  clientId?: number;
}

export type Exercise = {
  id: number
  name: string
  muscle_group: string
  reps: string[]
  sets: number
  duration?: number
  effort?: number
  machineOrMaterial: string
}

export type PlanificationDay = {
  day: string
  exercises: Array<Exercise>
}

export type WorkoutWeekDraft = {
  reasoning: string,
  user: PlanificationUser,
  planification: PlanificationDay[],
  motivational: string,
}

export type WorkoutWeek = {
  id?: string
  name: string
  description?: string
  planification: WorkoutWeekDraft,
  days: number
  createdAt: Date
  gymId: string
  authorId: string
  week: string
  fileUrl: string
  clientId?: number
  client?: Client
  clientGoal?: ClientGoal
  multipleTrainingId?: number
}

export type WorkoutWeekCreate = {
  name: string
  description: string
  planification: WorkoutWeekDraft
  multipleTrainingId?: number
};
export type WorkoutWeekCreateFree = {
  name: string
  description: string
  planification: WorkoutWeekDraft
  email: string
  phone: string
};

export type SignupData = {
  gymname: string,
  email: string,
  firstname: string,
  lastname: string,
  accept: boolean,
  password: string,
  passwordConfirmation: string,
  gender: string,
  phone: string,
};

export type Conversation = {
  id: number,
  threadId: string,
  user: { name: string, lastname: string },
  aiMessages: Array<{ message: string, sender: string, createdAt: string, id: number }>,
};

export type MultipleTraining = {
  id: number,
	createdAt: string,
	authorId: number,
	name: string,
	description: string,
	status: ProcessStatus,
	totalTrainings: number,
	currentCompleted: number,
	fileUrl: string,
  failed: number,
  errors: string[],
  workoutWeeks: WorkoutWeek[],
  planification?: WorkoutWeekDraft,
};

export type ClientGoal = {
  workouts: WorkoutWeek[],
  id: number,
  trainingPreferences: string,
  mainGoal: string,
  availableDays: string,
  trainingDuration: string,
  clientId: number,
  createdAt: Date,
};

export enum ProcessStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  ERRORED = 'ERRORED',
}

const stubPrograms = (p: any): Program => ({
  ...p,
  totalTime: p.steps.reduce((acc: number, curr: { duration: string; }) => acc + parseInt(curr.duration), 0),
})

const stubTrainingClasses = (c: any): TrainingClass => ({
  ...c,
})

const stubTrainings = (s: any): TrainingSession => ({
  ...s,
})

const stubRooms = (r: any): Room => ({
  ...r,
})

const stubRanking = (r: any): RankingElement => ({
  ...r,
})

const stubWorkoutWeek = (r: any): WorkoutWeek => ({
  ...r,
})

const stubMultipleTrainings = (r:any): MultipleTraining => ({
  ...r,
});

const api = {
  auth: {
    login: async(email: string, password: string) => {
      const res = await axios.post(`/auth/login`, {
        username: email.toLocaleLowerCase(),
        password,
      })
        .catch((err) => err.response);
      
      if (res?.status === 200 || res?.status === 201) {
        localStorage.setItem('token', res.data.access_token);
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res?.data.error,
        }
      }
    },
    verify: async(userId: number, code: string) => {
      const res = await axios.post(`/users/${userId}/verify`, {
        code,
      })
        .catch((err) => err.response);
      
      if (res?.status === 200 || res?.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res?.data.error,
        }
      }
    },

    signup: async(signupData: SignupData) => {
      const res = await axios.post(`/gyms/signup`, {
        email: signupData.email.toLocaleLowerCase(),
        name: signupData.gymname,
        firstName: signupData.firstname,
        lastName: signupData.lastname,
        birthdate: dayjs().subtract(30, 'years').toDate(),
        gender: signupData.gender,
        phone: signupData.phone,
        password: signupData.password,
        passwordConfirmation: signupData.passwordConfirmation,
        logoUrl: '',
      })
        .catch((err) => err.response);
      
      if (res?.status === 200 || res?.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res?.data.error,
        }
      }
    },
    resetPassword: async(email: string) => {
      const res = await axios.post(`/auth/reset-password`, {
        email,
      })
        .catch((err) => err.response);
      
      if (res?.status === 200 || res?.status === 201) {
        return {
          success: true,
        }
      }else {
        return {
          success: false,
        }
      }
    },
    profile: async () => {
      const token = localStorage.getItem('token');
      const res = await axios.get(`/profile`, {
        headers: {
          Authorization: 'Bearer ' + token,
        }
      }).catch((err) => err.response);

      if (res?.status === 200 || res?.status === 201) {
        return {
          data: {
            ...res.data,
            Professor: res.data.Professor?.length ? res.data.Professor[0] : null,
            Client: res.data.Client?.length ? res.data.Client[0] : null,
          },
          success: true,
        }
      }else {
        return {
          success: false,
          error: res?.data.error,
        }
      }
    }
  },
  professors: {
    getById:  async (id: number) => {
      const res = await axios.get(`/professors/${id}`);
      if (res.status === 200) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
  },
  clients: {
    getById:  async (id: number) => {
      const res = await axios.get(`/clients/${id}`);
      if (res.status === 200) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    get:  async (search: string) => {
      const res = await axios.get(`/clients?search=${search}`);
      if (res.status === 200) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    getCurrentTraining:  async (id: number) => {
      const res = await axios.get(`/clients/${id}/current-training`);
      if (res.status === 200) {
        return {
          data: res.data as TrainingSession,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    getTrainings:  async (id: number) => {
      const res = await axios.get(`/clients/${id}/trainings`);
      if (res.status === 200) {
        return {
          data: res.data as TrainingSession[],
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    addBand: async (clientId: number, data: any) => {
      const res = await axios.post(`/clients/${clientId}/band`, {
        bandCode: data.bandCode,
        // first name, etc
      }).catch(err => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    },
    addGoal: async (clientId: number, data: any) => {
      console.log('sending data', data)
      const res = await axios.post(`/clients/${clientId}/goals`, {
        trainingPreferences: data.trainingPreferences,
        mainGoal: data.mainGoal,
        availableDays: data.availableDays,
        trainingDuration: data.trainingDuration,
        // first name, etc
      }).catch(err => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    },
    getGoals:  async (id: number) => {
      const res = await axios.get(`/clients/${id}/goals`).catch(err => err.response);
      if (res.status === 200) {
        return {
          data: res.data as ClientGoal[],
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    addHealth: async (clientId: number, data: any) => {
      const res = await axios.post(`/clients/${clientId}/health`, {
        age: data.age,
        weight: data.weight,
        height: data.height,
        basal: 70,
        max: 190,
        previousExperience: data.previousExperience,
        injuries: data.previousInjuries,
        // first name, etc
      }).catch(err => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    },
    create: async (data: ClientUpdate) => {
      const res = await axios.post(`/clients/`, {
        nick: data.nick,
        user: {
          name: data.name,
          lastname: data.lastname,
          document: data.document,
          email: data.email,
          birthdate: data.birthdate,
          gender: data.gender,
        },
        weight: 70,
        height: 170,
        basal: 70,
        max: 190,
        profession: data.profession,
      }).catch(err => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    },
    update: async (clientId: number, data: any) => {
      const res = await axios.put(`/clients/${clientId}`, {
        ...data
        // nick
      }).catch(err => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    },
    getPlanifications:  async (id: number) => {
      const res = await axios.get(`/clients/${id}/planifications`).catch(err => err.response);
      if (res.status === 200) {
        return {
          data: res.data as WorkoutWeek[],
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    generatePlanification: async (clientId: number) => {
      const res = await axios.post(`/multiple-trainings/for-client/${clientId}`)
        .catch(err => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data as MultipleTraining,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    },
    createWorkoutWeek: async (clientId: number, data: WorkoutWeekCreate) => {
      const res = await axios.post(`/clients/${clientId}/workoutweek`, data)
        .catch(err => err.response);
  
      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data as WorkoutWeek,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    },
  },
  trainingClasses: {
    get:  async () => {
      const res = await axios.get(`/tClasses`).catch((err) => err.response);
      if (res.status === 200) {
        return {
          data: res.data?.map(stubTrainingClasses),
          success: true,
        }
      }else {
        return {
          data: [],
          success: false,
          error: res.data,
        }
      }
    },
    getById:  async (id: number) => {
      const res = await axios.get(`/tClasses/${id}`).catch((err) => err.response);
      if (res.status === 200) {
        return {
          data: stubTrainingClasses(res.data),
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    create: async (trainingClass: any) => {
      const res = await axios.post(`/tClasses`, trainingClass, {
        headers: {
          ContentType: "application/json"
        }
      }).catch((err) => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: stubTrainingClasses(res.data),
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    },
    toggleHidden: async (trainingClassId: number) => {
      const res = await axios.post(`/tClasses/${trainingClassId}/toggle-hidden`, {}, {
        headers: {
          ContentType: "application/json"
        }
      }).catch((err) => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: stubTrainingClasses(res.data),
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    }
  },
  programs: {
    get: async () => {
      const res = await axios.get(`/programs`);
      if (res.status === 200) {
        return {
          data: res.data.map(stubPrograms),
          success: true,
        }
      }else {
        return {
          data: [],
          success: false,
          error: res.data,
        }
      }
    },
    create: async (program: Program) => {
      const res = await axios.post(`/programs`, program, {
        headers: {
          ContentType: "application/json"
        }
      }).catch((err) => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          data: [],
          success: false,
          error: res.data?.error,
        }
      }
      
    }
  },
  trainingSessions: {
    start: async (trainingClassId: string) => {
      const res = await axios.post(`/trainings/${trainingClassId}/start`, {}, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch(res => res.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data.data,
          success: true,
        }
      }else {
        return {
          data: {},
          success: false,
          error: res.data.message,
        }
      }
    },
    create: async (clientId: number, bandCode: string, trainingClass?: TrainingClass) => {
      const res = await axios.post(`/trainings`, {
          name: `Entrenamiento de ${dayjs().format('DD/MM/YYYY HH:mm')}`,
          autologout: false,
          professorId: trainingClass?.professorId,
          bandCode,
          clientId: clientId,
          tClassId: trainingClass?.id,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch(res => res.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          data: {},
          success: false,
          error: res.data?.error,
        }
      }
    },
    createQuickTraining: async (doc: string, bandCode: string, roomId: string, professorId: number, programId: string) => {
      const res = await axios.post(`/professors/${professorId}/quick-training`, {
          name: `Entrenamiento de ${dayjs().format('DD/MM/YYYY HH:mm')}`,
          autologout: false,
          professorId,
          bandCode,
          document: doc,
          roomId,
          programId,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch(res => res.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          data: {},
          success: false,
          error: res.data?.error,
        }
      }
    },
    end: async (trainingId: number) => {
      const res = await axios.post(`/trainings/${trainingId}/end`, {
      }, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (res.status === 200 || res.status === 201) {
        return {
          success: true,
        }
      }else {
        return {
          data: {},
          success: false,
          error: res.data,
        }
      }
    },

    get: async (id: string) => {
      const res = await axios.get(`/trainings/${id}`);
      if (res.status === 200) {
        return {
          data: stubTrainings(res.data),
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    data: async (trainingId: string | number, roomId: any, bandCode: string) => {
      const res = await axios.get(`/trainings/${trainingId}/data?room=${roomId}&band=${bandCode}`);
      if (res.status === 200) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
  },
  rooms: {
    get: async () => {
      const res = await axios.get(`/rooms`);
      if (res.status === 200) {
        return {
          data: res.data.map(stubRooms),
          success: true,
        }
      }else {
        return {
          data: [],
          success: false,
          error: res.data,
        }
      }
    },
    getWithTrainings: async () => {
      const res = await axios.get(`/rooms/trainings`).catch(err => err.response);
      if (res.status === 200) {
        return {
          data: res.data.map(stubRooms),
          success: true,
        }
      }else {
        return {
          data: [],
          success: false,
          error: res.data,
        }
      }
    }
  },
  rankings: {
    getByRoom: async (roomId: number) => {
      const res = await axios.get(`/rankings/current?room=${roomId}`).catch(err => err.response);
      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data.map(stubRanking),
          success: true,
        }
      }else {
        return {
          data: [],
          success: false,
          error: res.data,
        }
      }
    }
  },
  openai: {
    chat: async (threadId: string = '', message: string, assistant?: string): Promise<any> => {
      /*return {
        data: { content: [{ text: { value: message }}]},
        success: true,
      };*/
      const assistantQuery = assistant ? `assistant=${assistant}` : '';
      const res = await axios.post(`/openai/chat/${threadId}?${assistantQuery}`, {
         message,
      }).catch(err => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        };
      } else {
        return {
          data: {},
          success: false,
        };
      }
    },
    /**
     * planify wizard
     * @param data 
     */
    planify: async (message: string): Promise<any> => {
      /*return {
        data: { content: [{ text: { value: message }}]},
        success: true,
      };*/
      const res = await axios.post(`/openai/gen-planification?assistant=fit`, {
         message,
      }).catch(err => err.response);

      if (res.status === 200 || res.status === 201) {
        try {
          const { msg, workoutWeek } = extractWorkoutWeekFromText(res.data.content[0].text.value);
          return {
            data: workoutWeek,
            success: true,
          };
        } catch (error) {
          return {
            data: {},
            success: false,
            error,
          }
        }
      } else {
        return {
          data: {},
          success: false,
        };
      }
    },
    /**
     * generate exercise
     * @param data 
     */
    generateExercise: async (message: string): Promise<{ data?: any, success: boolean, error?: string}> => {
      /*return {
        data: { content: [{ text: { value: message }}]},
        success: true,
      };*/
      const res = await axios.post(`/openai/gen-exercise?assistant=fit`, {
         message,
      }).catch(err => err.response);

      if (res.status === 200 || res.status === 201) {
        try {
          const { msg, exercise } = extractExerciseFromText(res.data.content[0].text.value);
          return {
            data: exercise,
            success: true,
          };
        } catch (error: any) {
          return {
            success: false,
            error: error.toString(),
          }
        }
      } else {
        return {
          success: false,
        };
      }
    },
    programDraft: async (roomId: number, time: number, details: string) => {
      const res = await axios.post(`/openai/program`, {
        data: {
          roomId,
          minutes: time,
          details,
        }
      }).catch(err => err.response);
      if (res.status === 200 || res.status === 201) {
        if (!res.data?.steps || !res.data?.reasoning) {
          return {
            data: {},
            error: res.data?.error || 'No se ha podido completar la operación. Sé mas detallado al completar tus objetivos y ánimo.',
            success: false,
          }
        }
        return {
          data: {
            ...res.data,
            description: res.data?.reasoning,
            steps: res.data.steps.map((step: any) => ({
              ...step,
              efforts: step.efforts * 100,
              name: step.description, // removed the step.name
            })),
          },
          success: true,
        };
      } else {
        return {
          data: {},
          success: false,
          error: res.data,
        }
      }
    },
    transcript: async (file: Blob) => {
      // Create a new FormData object
      const formData = new FormData();
      
      // Append the Blob object to the FormData
      formData.append('file', file, `voicerecording_${Date.now()}.ogg`);
      
      // Make an HTTP POST request to your server endpoint
      const res = await axios.post(`/openai/transcript`,formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .catch(err => err.response);;
  
      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }
      return {
        success: false,
        error: res.data,
      }
    }
  },
  conversations: {
    get: async (page: number, limit: number): Promise<{ data: Conversation[], success: boolean, error?: string}> => {
      const res = await axios.get(`/threads?page=${page}&limit=${limit}`);
      if (res.status === 200) {
        return {
          data: res.data?.filter((c: any) => c.aiMessages?.length > 1),
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
          data: [],
        }
      }
    },
    getOne: async (threadId: string): Promise<{ data?: Conversation, success: boolean, error?: string}> => {
      const res = await axios.get(`/threads/${threadId}/complete`);
      if (res.status === 200) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
          data: undefined,
        }
      }
    }
  },
  gyms: {
    get: async (id: number) => {
      const res = await axios.get(`/gyms/${id}`).catch((err) => err.response);
      if (res.status === 200) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    }
  },
  workoutWeeks: {
    all: async (gymId: number) => {
      const res = await axios.get(`/workoutweeks/`);
      if (res.status === 200) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    get: async (id: number) => {
      const res = await axios.get(`/workoutweeks/${id}`);
      if (res.status === 200) {
        return {
          data: {
            ...res.data,
            planification: JSON.parse(res.data.planification),
          },
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    getAll: async (page: number, limit: number): Promise<{ data: WorkoutWeek[], success: boolean, error?: string}> => {
      const res = await axios.get(`/workoutWeeks?page=${page}&limit=${limit}`);
      if (res.status === 200) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          data: [],
          error: res.data,
        }
      }
    },
    sendEmail: async (id: number, email: string) => {
      const res = await axios.post(`/workoutweeks/${id}/send-email`, { email });
      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    create: async (workoutWeek: WorkoutWeekCreate) => {
      const res = await axios.post(`/workoutweeks/`, workoutWeek, {
        headers: {
          ContentType: "application/json"
        }
      }).catch((err) => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: stubWorkoutWeek(res.data),
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    },
    createFree: async (workoutWeek: WorkoutWeekCreateFree) => {
      const res = await axios.post(`/workoutweeks/create-free`, workoutWeek, {
        headers: {
          ContentType: "application/json"
        }
      }).catch((err) => err.response);

      if (res.status === 200 || res.status === 201) {
        return {
          data: stubWorkoutWeek(res.data),
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data?.error,
        }
      }
    },
  },
  multipleTrainings: {
    get:  async (page: number, size: number) => {
      const res = await axios.get(`/multiple-trainings?page=${page}&limit=${size}`)
        .catch((err) => err.response);
      if (res.status === 200) {
        return {
          data: res.data?.map(stubMultipleTrainings),
          success: true,
        }
      }else {
        return {
          data: [],
          success: false,
          error: res.data,
        }
      }
    },
    getById:  async (id: number) => {
      const res = await axios.get(`/multiple-trainings/${id}`).catch((err) => err.response);
      if (res.status === 200) {
        return {
          data: stubMultipleTrainings(res.data),
          success: true,
        }
      }else {
        return {
          success: false,
          error: res.data,
        }
      }
    },
    create: async (file: Blob) => {
      // Create a new FormData object
      const formData = new FormData();
      // Append the Blob object to the FormData
      formData.append('file', file);
      
      // Make an HTTP POST request to your server endpoint
      const res = await axios.post(`/multiple-trainings/upload`,formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .catch(err => err.response);;
  
      if (res.status === 200 || res.status === 201) {
        return {
          data: res.data,
          success: true,
        }
      }
      return {
        success: false,
        error: res.data?.error,
      }
    },
  },
};

export default api;
