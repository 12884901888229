import React, { ReactElement, FC, useEffect, useState, useRef, useContext } from "react";
import { Box, Button, Typography, Alert, CircularProgress, AlertTitle, Grow, IconButton, ListItemText, AccordionSummary, Accordion, AccordionDetails, Chip, List, ListItem, ListItemAvatar, ListItemButton, Badge } from "@mui/material";
import RoomIcon from '@mui/icons-material/Room';
import api, { MultipleTraining, ProcessStatus, WorkoutWeek } from "../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import TrainingProgramDetailsComponent from "../components/TrainingDetails";
import { PageTitle } from "../components/PageTitle";
import { UserContext } from "../contexts/userContext";
import WorkoutaTableComponent from "../components/chat/WorkoutTableComponent";
import { ArrowBack, CalendarToday, ErrorOutline, HailOutlined, ViewWeek } from "@mui/icons-material";
import dayjs from "dayjs";
import WorkoutDialogEmail from "../components/WorkoutDialogEmail";
import DownloadButton from "../components/common/DownloadButton";
import UploadButton from "../components/common/UploadButton";
import { PageTitleWithBack } from "../components/common/PageTitleWithBack";


type MultipleTrainingsState = {
  data?: MultipleTraining,
  status: string,
  error?: string | boolean,
}

const MultipleTrainingsPage: FC<any> = (): ReactElement => {
  const containerRef = useRef<any>();
  const { id = '' } = useParams();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [multipleTrainings, setMultipleTrainings] = useState<MultipleTrainingsState>({ status: "initial" });
  const isCreate = id === 'add';
  const [percentage, setPercentage] = useState(1);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [workouts, setWorkouts] = useState<Array<WorkoutWeek>>([]);
  
  const getMultipleTrainings = async (id: number) => {
    setMultipleTrainings({ status: "loading" })
    const multipleTrainings = await api.multipleTrainings.getById(id);
    if (multipleTrainings.success && multipleTrainings.data) {
      console.log('holaaa', multipleTrainings.data)
      const data: MultipleTraining = multipleTrainings.data;
      const currentCompleted = (multipleTrainings.data.currentCompleted);
      if (data.status === ProcessStatus.FINISHED) {
        setPercentage(currentCompleted / multipleTrainings.data.totalTrainings);
        if (errors.length !== data.errors.length) {
          setErrors(data.errors);
        }
        if (workouts.length !== data.workoutWeeks.length) {
          setWorkouts(data.workoutWeeks);
        }
        console.log('hey que paso', workouts.length, currentCompleted, data.status)
        // setTimeout(() => getMultipleTrainings(id), 3000);

        setMultipleTrainings({ data, status: "loading" });
      }else {

        setErrors(data.errors);
       // getWorkoutWeeks(id);
        setTimeout(() => getMultipleTrainings(id), 3000);
        setWorkouts(data.workoutWeeks);
  
        setMultipleTrainings({ data, status: "success" });
      }
    } else {
      setMultipleTrainings({ status: "error", error: multipleTrainings.error })
    }
  }

  const getWorkoutWeeks = async (id: number) => {
    const multipleTrainings = await api.multipleTrainings.getById(id);

    if (multipleTrainings.success && multipleTrainings.data) {
      const data: MultipleTraining = multipleTrainings.data;
      const currentCompleted = (multipleTrainings.data.currentCompleted);
      // if workouts are different from the completed, we need to still gather
      if (data.status === ProcessStatus.FINISHED) {
        setTimeout(() => getMultipleTrainings(id), 3000);
      }else {
      }
    }
  }

  const handleUploadFile = async (input: any) => {
    setMultipleTrainings({ status: 'loading' });
    const res = await api.multipleTrainings.create(input.target?.files[0]);
    if (res.success) {
      navigate(`/multiple-trainings/${res.data.id}`);
    }else {
      setMultipleTrainings({ status: 'error', error: res.error });
    }
  }

  const handleGoBack = () => navigate('/multiple-trainings');


  useEffect(() => {
    if (!isCreate) {
      getMultipleTrainings(parseInt(id));
    }
  }, [id]);
console.log('adata', multipleTrainings.data)
  return isCreate ? (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      ref={containerRef}
    >
      <PageTitle title={'Crear múltiples planificaciones'} />
      <Box mb={2} display="flex" flexDirection="column" gap={1}>
        <Box>
          <Typography variant="body1">Descargá el archivo base para completar los datos correctamente.</Typography>
          <DownloadButton href="https://storage.googleapis.com/domav2-clases/domav2-images/multiple_trainings_test.xlsx" label="Descargar modelo de archivo" />
        </Box>
        <Box mt={2}>
          <Typography variant="body1">Una vez completado, subí el archivo para que comencemos a planificar.</Typography>
          <UploadButton onSelect={handleUploadFile} status={multipleTrainings.status}/>
        </Box>
        {multipleTrainings.status === 'error' && (
          <Alert severity="error">
            <AlertTitle>{multipleTrainings.error === 'file' ? 'El archivo debe ser de tipo .xlsx' : `Hubo un inconveniente: ${multipleTrainings.error}`}</AlertTitle>
          </Alert>
        )}
      </Box>
    </Box>
  ) : (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      ref={containerRef}
    >
      <PageTitleWithBack title='Batch de planificaciones' onBack={handleGoBack} />
      <Box mb={2} display="flex" flexDirection="column" gap={1}>
        <Typography variant="h3">{multipleTrainings.data?.name}</Typography>
        <Box display="flex" gap={1}>
          <CalendarToday /><Typography variant="body1"><b>Creada:</b> {dayjs(multipleTrainings.data?.createdAt).format('MM/DD/YYYY HH:mm')}</Typography>
        </Box>
        <Box>
          {multipleTrainings.data?.status === ProcessStatus.FINISHED && (
            <Typography variant="body1">
              {`${workouts?.length}/${multipleTrainings.data?.totalTrainings} entrenamientos generados.`}
            </Typography>
          )}
        </Box>
        {multipleTrainings.data?.status !== ProcessStatus.FINISHED && (
          <Box>
            <CircularProgress />
          </Box>
        )}
        {percentage < 1 && multipleTrainings.status === 'loading' && (
          <Alert severity="info">
            <AlertTitle>La magia está comenzando...</AlertTitle>
            {multipleTrainings.data?.failed && (<AlertTitle>Reintentando algunas planificaciones...</AlertTitle>)}
            <AlertTitle>{Math.ceil(percentage * 100)}% completado</AlertTitle>
          </Alert>
        )}
        <Accordion TransitionProps={{ unmountOnExit: true }} >
          <AccordionSummary>
            <Badge badgeContent={errors.length} color="error">
              <ErrorOutline color="action" />
            </Badge>
            <Typography variant="subtitle1" sx={{ marginLeft: 3 }}>Errors</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {errors.map((e, index) => (
              <Alert severity="error" key={index}>{e}</Alert>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion TransitionProps={{ unmountOnExit: true }} >
          <AccordionSummary>
            <Badge badgeContent={workouts.length} color="success">
              <HailOutlined color="action" />
            </Badge>
            <Typography variant="subtitle1" sx={{ marginLeft: 3 }}>Planificaciones</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {workouts.map((w, index) => (
                <ListItem key={index} secondaryAction={
                  <Link to={`/workoutweeks/${w.id}`} >
                    Ver
                  </Link>
                }>
                  
                  <ListItemAvatar>
                    <HailOutlined />
                  </ListItemAvatar>
                  <ListItemText primary={w.name} secondary={`${w.days} días por semana.`}>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
        

      </Box>
    </Box>
  );
};

export default MultipleTrainingsPage;