import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardActions, Typography, Accordion, AccordionSummary, AccordionDetails, Button, List, ListItem, ListItemText, ListItemSecondaryAction, ListItemButton, CardHeader, Modal, Slide, Dialog, DialogTitle, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formattedTime } from '../../utils/times';
import { formatDate } from '../../utils/dates';
import api, { ClientGoal, WorkoutWeek } from '../../api';
import { Link, useNavigate } from 'react-router-dom';
import GoalForm from './goal-form';
import { TransitionProps } from '@mui/material/transitions';
import Transition from '../common/Transition';

interface Planification {
  id: number;
  name: string;
  description: string;
}

interface PlanificationCardProps {
  goal?: ClientGoal;
  clientId?: number;
}
interface WorkoutsRequestType {
  status: string;
  error: string;
  data?: WorkoutWeek[];
}

const PlanificationCard: React.FC<PlanificationCardProps> = ({ goal, clientId }) => {
  const [workoutsRequest, setWorkoutsRequest] = useState<WorkoutsRequestType>({ status: 'initial', error: '' });
  const navigate = useNavigate();

  const getPlanification = async (clientId: number) => {
    setWorkoutsRequest({ status: 'loading', error: '' });
    const res = await api.clients.getPlanifications(clientId);
    if (res.success) {
      setWorkoutsRequest({ status: 'success', error: '', data: res.data });
    } else {
      setWorkoutsRequest({ status: 'error', error: res.error || 'Error loading planifications' });
    }
  }
  const handleCreatePlanification = async () => {
    navigate(`/clients/${clientId}/planification`);
  };
  const handleSeePlanification = (id: string) => (e: any) => {
    navigate(`/workoutweeks/${id}`);
  };

  useEffect(() => {
    if (clientId) {
      getPlanification(clientId);
    }
  }, [clientId])

  const workout = workoutsRequest.data?.length ? workoutsRequest.data[0] : undefined;
  return (
    <>
      {workoutsRequest.status === 'loading' && (
        <Skeleton variant="rectangular" height={200} />
      )}
      {workout?.id && workoutsRequest.status === 'success' && (
        <Card>
          <CardContent>
            <Typography variant="h6" component="div">
              Fecha: {workout.createdAt ? formatDate(workout.createdAt) : '-'}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {workout.name} 
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {workout.description}
            </Typography>
            
          </CardContent>
          <CardActions>
            <Button size="small" color="secondary" disabled>
              Ver historial
            </Button>

            <Button size="small" color="primary" onClick={handleSeePlanification(workout.id)}>
              Ver planificación
            </Button>
          </CardActions>
        </Card>
      )}
      {!workout && workoutsRequest.status === 'success' && (
        <Card>
          <CardContent>
            <Typography variant="h6" component="div">
              Sin planificación
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Con los datos completos, puedes generar una planificación haciendo click en el botón de abajo.
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default PlanificationCard;