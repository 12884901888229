
export const seconds: Array<number> = [];
for (let i = 5; i <= 20 * 60; i += 5) {
  seconds.push(i);
}

export const formattedTime = (seconds: number) => {
  const mdl = seconds % 60;
  if (mdl < 10 && mdl) {
    return `${Math.floor(seconds/60)}' 0${mdl}'' `;
  }else {
    return !mdl ? `${Math.floor(seconds/60)}' 00'' ` : `${Math.floor(seconds/60)}' ${mdl}'' `
  }
}
