import { ReactElement, FC, useState, ChangeEvent, useEffect, useContext } from "react";
import { Alert, AlertTitle, Box, Button, Divider, FormControl, InputAdornment, InputLabel, NativeSelect, TextField, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import api, { Program, ProgramStep, Room } from "../api";
import { seconds, formattedTime } from "../utils/times";
import { NfcOutlined } from "@mui/icons-material";
import { NfcReader } from "../utils/NfcReader";
import { PageTitle } from "../components/PageTitle";
import { UserContext } from "../contexts/userContext";

type RoomsState = {
  data: Array<Room>,
  status: string,
  error?: string,
}

type TrainingProgramState = {
  data: Array<Program>,
  status: string,
  error?: string,
}

type SessionValues = {
  room: string,
  doc: string,
  professorId: string,
  bandId: string,
  trainingProgram: string,
}

type ReadingTagState = {
  status: string,
  value: string,
  error?: string,
};

type CreateSessionState = {
  status: string,
  error?: string,
};

const INITIAL_SESSION = { room: '', doc: '', trainingProgram: '', professorId: '', bandId: '' };

const reader = new NfcReader();
const QuickSessionPage: FC<any> = (): ReactElement => {
  const [rooms, setRooms] = useState<RoomsState>({ data: [], status: 'initial' });
  const [trainingProgram, setTrainingProgram] = useState<TrainingProgramState>({ data: [], status: 'initial' });
  const [sessionValues, setSessionValues] = useState<SessionValues>(INITIAL_SESSION)
  const [createSession, setCreateSession] = useState<CreateSessionState>({ status: "initial" })
  const [readingTag, setReadingTag] = useState<ReadingTagState>({ status: "initial", value: "" });
  const { user } = useContext(UserContext);


  const getRooms = async () => {
    setRooms({ status: "loading", data: [] })
    const res = await api.rooms.get();
    if (res.success) {
      setRooms({ data: res.data, status: "success" });
      if (res.data?.length) {
        setSessionValues((values) => ({ ...values, room: res.data[0].id }));
      }
    } else {
      setRooms({ data: [], status: "error", error: res.error });
    }
  }

  const getTrainingPrograms = async () => {
    setTrainingProgram({ status: "loading", data: [] })
    const res = await api.programs.get();
    if (res.success) {
      setTrainingProgram({ data: res.data, status: "success" });
      if (res.data?.length) {
        setSessionValues((values) => ({ ...values, trainingProgram: res.data[0].id }));
      }
    } else {
      setTrainingProgram({ data: [], status: "error", error: res.error });
    }
  }

  const handleOnSelectRoom = (event: ChangeEvent<HTMLSelectElement>) => setSessionValues((values) => ({ ...values, room: event.currentTarget.value }));
  const handleSelectOnProgram = (event: ChangeEvent<HTMLSelectElement>) => setSessionValues((values) => ({ ...values, trainingProgram: event.currentTarget.value }));
  const handleOnChangeDoc = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => setSessionValues((values) => ({ ...values, doc: currentTarget?.value }))

  const handleCreateQuickSession = async (doc: string, bandCode: string, roomId: string, professorId: number, programId: string) => {
    setCreateSession({ status: "loading" })
    const res = await api.trainingSessions.createQuickTraining(doc, bandCode, roomId, professorId, programId);
    if (res.success) {
      setCreateSession({ status: "success" });
    } else {
      setCreateSession({ status: "error", error: res.error });
    }
    setTimeout(() => setCreateSession({ status: "initial" }), 3000);
  }

  const resetForm = () => {
    setSessionValues((values) => ({ ...values, doc: '' }));
    setReadingTag({ status: 'INITIAL', value: '' });
    try {
      reader.stopScan();
    } catch (e) {
      console.error(e);
    }
  }

  const handleClickScanTag = async () => {
    setReadingTag({ status: 'reading', value: '' });
    try {
      const result = await reader.scanTag();
      let bandCode = '';
      if (Array.isArray(result)) {
        alert(result[0]);
        bandCode = result[0];
        handleCreateQuickSession(sessionValues.doc, bandCode, sessionValues.room, user.professorId, sessionValues.trainingProgram);
      }else {
        alert(JSON.stringify(result))
      }
    } catch(e) {
      alert(e)
    }
    resetForm();
  }

  useEffect(() => {
    getRooms();
    getTrainingPrograms();
  }, [])


  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
    >
      <Box display="flex" flexDirection="column" p={1} maxWidth={400}>
        <Box display="flex" flexDirection="column" mb={2}>
          <Box display="flex" justifyContent="space-around" p={2}>
            <FormControl style={{ flex: 3 }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Sala
              </InputLabel>
              <NativeSelect
                value={sessionValues.room}
                onChange={handleOnSelectRoom} 
              >
                {rooms.data.map(s => <option key={s.id} value={s.code}>{s.name}</option>)}
              </NativeSelect>
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="space-around" p={2}>
            <FormControl style={{ flex: 3 }}>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Programa de entrenamiento
              </InputLabel>
              <NativeSelect
                value={sessionValues.trainingProgram}
                onChange={handleSelectOnProgram} 
              >
                {trainingProgram.data.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
              </NativeSelect>
            </FormControl>
          </Box>
          <Box display="flex" justifyContent="space-around" p={2}>
            <TextField
              style={{ flex: 3 }}
              variant="standard"
              value={sessionValues.doc}
              type="number"
              onChange={handleOnChangeDoc}
              label="Documento"
            />
          </Box>
          <Divider style={{ margin: '20px 0' }}/>
        </Box>
        <Box>
          {createSession.status === 'success' && (
            <Alert severity="success">
              <AlertTitle>Entrenamiento creado</AlertTitle>
            </Alert>
          )}
          {createSession.status === 'error' && (
            <Alert severity="error">
              <AlertTitle>{createSession.error || 'Ha habido un error'}</AlertTitle>
            </Alert>
          )}
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          {readingTag.status === 'reading' ? (
            <NfcOutlined color="primary" fontSize="large" sx={{ transform: 'scale(2.5)' }}/>
          ) : (
            <FormControl>
              <Button variant="contained" color="primary" onClick={handleClickScanTag} disabled={sessionValues.doc.length < 6}>
                Escanear banda
              </Button>
            </FormControl>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default QuickSessionPage;
