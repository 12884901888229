import React, { ReactElement, FC } from "react";
import { Box, Typography,Container, CircularProgress, ListItemButton, ListItemAvatar, ListItemText, List } from "@mui/material";
import InsightsIcon from '@mui/icons-material/Insights';
import api, { Room } from "../api";
import { Link, useNavigate } from "react-router-dom";

type RoomsListProps = {
  data?: Room[],
  status: string,
  error?: string,
}

const RoomsList: FC<RoomsListProps> = ({ data, error, status }): ReactElement => {
  return (
    <List
      sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' } }}
      component="nav"
    >
        {status === 'loading' && (<CircularProgress size="small" />)}
        {data?.map(room => (
          <Link key={room.id} to={`/ranking-room/${room.id}`}>
            <ListItemButton>
              <ListItemAvatar>
                <InsightsIcon />
              </ListItemAvatar>
              <ListItemText
                primary={room.name}
                secondary={`${room.trainings?.length} personas entrenando`}
              />
            </ListItemButton>
          </Link>
        ))}
      </List>
  );
};

export default RoomsList;
