import React, { ReactElement, FC, useState } from "react";
import { Box, Typography,Container, Tabs, Tab, AppBar } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import TabPanel from "../components/common/TabPanel";
import QuickSessionPage from "./QuickSessionPage";
import Clients from "./Clients";

const TAB_CLIENTS = 0;
const TAB_QUICK = 1;

const ClientsPage: FC<any> = (): ReactElement => {
  const [tab, setTab] = useState(TAB_CLIENTS);

  
  return (
    <Box
    >
      <PageTitle title="Clientes" />    
      <Clients />
      
    </Box>
  );
};

export default ClientsPage;