import React, { useState } from 'react';
import ClientGoalAndPreferences from '../common/ClientGoalAndPreference';
import { Alert, Box, Button, DialogActions, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { HeightOutlined, MonitorWeightOutlined } from '@mui/icons-material';
import { ClientHealth } from '../../api';

type Props = {
  onSave: ({ weight, height, previousInjuries, previousExperience }: { weight: number, height: number, previousInjuries: string, previousExperience: string }) => void;
  status: string;
  onCancel: () => void;
  health?: ClientHealth; 
};

const HealthForm: React.FC<Props> = ({ onSave, status, health, onCancel }: Props) => {
  const [weight, setWeight] = useState<string>(health?.weight.toString() || '');
  const [height, setHeight] = useState<string>(health?.height.toString() || '');
  const [previousExperience, setPreviousExperience] = React.useState<string>(health?.previousExperience || '');
  const [previousInjuries, setPreviousInjuries] = React.useState<string>(health?.injuries || '');
  const [previousInjuriesBool, setPreviousInjuriesBool] = React.useState<string>(health?.injuries ? 'si' : 'no');

  const handleExperienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousExperience((event.target as HTMLInputElement).value);
  };

  const handlepreviousInjuriesBoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousInjuriesBool((event.target as HTMLInputElement).value);
  };


  const handlePreviousInjuriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousInjuries((event.target as HTMLInputElement).value);
  };

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeight(event.target.value);
  };

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHeight(event.target.value);
  };

  const handleSave = () => {
    onSave({ weight: parseInt(weight), height: parseInt(height), previousInjuries, previousExperience });
  };

  return (
    <Box width="100%" px={[2, 4]} py={[1,2]}>
      <Box display="flex" gap={2}>
        <TextField
          label="Peso en kilogramos"
          type="number"
          value={weight !== null ? weight : ''}
          onChange={handleWeightChange}
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MonitorWeightOutlined /> 
              </InputAdornment>
            ),
          }}
          inputProps={{ min: 0, max: 150 }}
        />
        <TextField
          label="Tu altura en centímetros"
          type="number"
          value={height !== null ? height : ''}
          onChange={handleHeightChange}
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: (
            <InputAdornment position="start">
              <HeightOutlined />
            </InputAdornment>
            ),
          }}
          inputProps={{ min: 0, max: 220 }}
        />
      </Box>
      <Box width="100%" mb={2}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
        La experiencia previa es crucial para una planificación adecuada. Consideraremos cualquier lesión que hayas tenido para diseñar ejercicios apropiados.
      </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="previousExperience"
            name="previousExperience"
            value={previousExperience}
            onChange={handleExperienceChange}
            sx={{ flexDirection: 'column' }}
          >
            <FormControlLabel value="sedentario" control={<Radio />} label="Sedentario (< un mes)" />
            <FormControlLabel value="principiante" control={<Radio />} label="Principiante (1 a 6 meses)" />
            <FormControlLabel value="medio" control={<Radio />} label="Medio (6 a 12 meses)" />
            <FormControlLabel value="avanzado" control={<Radio />} label="Avanzado (más de 12 meses)" />
          </RadioGroup>
        </FormControl>
        <Typography variant="subtitle1" color="grey" sx={{ marginTop: 2 }}>
          Selecciona una opción adicional.
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="previousInjuries"
            name="previousInjuries"
            value={previousInjuriesBool}
            onChange={handlepreviousInjuriesBoolChange}
            sx={{ flexDirection: 'column' }}
          >
            <FormControlLabel value="no" control={<Radio />} label="Sin lesiones previas" />
            <FormControlLabel value="si" control={<Radio />} label="He sufrido lesiones" />
          </RadioGroup>
        </FormControl>
        {previousInjuriesBool === 'si' && (
          <TextField
            label="Por favor, proporciona más detalles"
            variant="outlined"
            value={previousInjuries}
            onChange={handlePreviousInjuriesChange}
            fullWidth
            sx={{ marginTop: 2 }}
          />
        )}
      </Box>
      {status === 'error' && (
        <Alert sx={{ my: 2}} severity="error">Hubo un error al guardar los datos. Completá todos las requerimientos.</Alert>
      )}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={status === 'loading'}>
          Actualizar
        </Button>
        <Button variant="text" color="primary" onClick={onCancel} disabled={status === 'loading'}>
          Cancelar
        </Button>
      </DialogActions>
    </Box>
  );
};

export default HealthForm;