import React, { useState } from 'react';
import ClientGoalAndPreferences from '../common/ClientGoalAndPreference';
import { Alert, Box, Button, DialogActions, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Client, ClientGoal } from '../../api';

type Props = {
  onSave: ({ mainGoal, trainingPreferences, availableDays, trainingDuration }: { mainGoal: string, trainingPreferences: string, availableDays: string, trainingDuration: string }) => void;
  status: string;
  onCancel: () => void;
  goal?: ClientGoal;
};

const GoalForm: React.FC<Props> = ({ onSave, status, goal, onCancel }: Props) => {
  const [mainGoal, setMainGoal] = useState<string>(goal?.mainGoal || '');
  const [trainingPreferences, setTrainingPreferences] = useState<string>(goal?.trainingPreferences || '');
  const [availableDays, setAvailableDays] = useState<string>(goal?.availableDays || '');
  const [trainingDuration, setTrainingDuration] = useState<string>(goal?.trainingDuration || '');

  const handleMainGoalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMainGoal(event.target.value);
  };

  const handletrainingPreferencesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTrainingPreferences(event.target.value);
  };

  const handleAvailableDaysChange = (event: SelectChangeEvent<string>) => {
    setAvailableDays(event.target.value);
  };
  const handleDurationChange = (event: SelectChangeEvent<string>) => {
    setTrainingDuration(event.target.value);
  };

  const handleSave = () => {
    onSave({ mainGoal, trainingPreferences, availableDays, trainingDuration });
  };

  return (
    <Box width="100%" px={[2, 4]} py={[1,2]}>
      <Box width="100%" mb={2}>
        <ClientGoalAndPreferences
          mainGoal={mainGoal}
          exercisePreference={trainingPreferences}
          premium={true}
          onMainGoalChange={handleMainGoalChange}
          onExercisePreferenceChange={handletrainingPreferencesChange}
          direction="row"
        />
        <Box>
          <Box display="flex" justifyContent="space-between" gap={2}>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel id="training-frequency-label">Frecuencia semanal</InputLabel>
              <Select
                labelId="training-frequency-label"
                id="training-frequency"
                value={`${availableDays}`}
                label="Frecuencia semanal"
                onChange={handleAvailableDaysChange}
              >
                <MenuItem value={'1'}>1 vez por semana </MenuItem>
                <MenuItem value={'2'} >2 veces por semana </MenuItem>
                <MenuItem value={'3'}>3 veces por semana </MenuItem>
                <MenuItem value={'4'}>4 veces por semana </MenuItem>
                <MenuItem value={'5'}>5 veces por semana </MenuItem>
                <MenuItem value={'6'}>6 veces por semana </MenuItem>
                <MenuItem value={'7'}>7 veces por semana </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel id="training-duration-label">Duración del entrenamiento</InputLabel>
              <Select
                labelId="training-duration-label"
                id="training-duration"
                value={trainingDuration}
                label="Duración del entrenamiento"
                onChange={handleDurationChange}
              >
                <MenuItem value={"30"}>30 minutos</MenuItem>
                <MenuItem value={"45"}>45 minutos</MenuItem>
                <MenuItem value={"60"}>1 hora</MenuItem>
                <MenuItem value={"75"}>1 hora 15 minutos</MenuItem>
                <MenuItem value={"90"}>1 hora 30 minutos</MenuItem>
                <MenuItem value={"105"}>1 hora 45 minutos</MenuItem>
                <MenuItem value={"120"}>2 horas</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>
      {status === 'error' && (
        <Alert sx={{ my: 2}} severity="error">Hubo un error al guardar el objetivo. Completá todos las requerimientos.</Alert>
      )}
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={status === 'loading'}>
          Actualizar
        </Button>
        <Button variant="text" color="primary" onClick={onCancel} disabled={status === 'loading'}>
          Cancelar
        </Button>
      </DialogActions>
    </Box>
  );
};

export default GoalForm;