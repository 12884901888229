import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Button, useTheme } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { UserContext } from '../../contexts/userContext';
import { WorkspacePremiumOutlined } from '@mui/icons-material';

const Step6: React.FC = () => {
  const [trainingFrequency, setTrainingFrequency] = useState<number>(2);
  const [trainingDuration, setTrainingDuration] = useState<string>('1 hora');
  const { user } = useContext(UserContext);
  const { setData, data } = useWizard();
  const theme = useTheme();

  const handleFrequencyChange = (event: SelectChangeEvent) => {
    setTrainingFrequency(parseInt(event.target.value));
  };

  const handleDurationChange = (event: SelectChangeEvent) => {
    setTrainingDuration(event.target.value as string);
  };

  useEffect(() => {
    setData({ ...data, availabilityDaysPerWeek: trainingFrequency, timePerSession: trainingDuration });
  }, [ trainingFrequency, trainingDuration ]);
  const premium = user?.id ? true : false;

  return (
    <Box>
      {premium ? (
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
        ¿Que frecuencia de entrenamiento tendrás por semana?
      </Typography>
      ) : (<Typography variant="h6" sx={{ marginBottom: 2 }}>
        ¿Que frecuencia de entrenamiento tendrás por semana?
        Para esta <span style={ { textDecoration: 'underline' }}>prueba gratuita</span> ofrecemos una planificación de 2 veces por semana de 30 min de duración.
      </Typography>
      )}
      <Box display="flex" justifyContent="space-between" gap={2}>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="training-frequency-label">Frecuencia semanal</InputLabel>
          <Select
            labelId="training-frequency-label"
            id="training-frequency"
            value={`${trainingFrequency}`}
            label="Frecuencia semanal"
            onChange={handleFrequencyChange}
          >
            <MenuItem value={1} disabled={!premium}>1 vez por semana <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
            <MenuItem value={2} >2 veces por semana </MenuItem>
            <MenuItem value={3} disabled={!premium}>3 veces por semana <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
            <MenuItem value={4} disabled={!premium}>4 veces por semana <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
            <MenuItem value={5} disabled={!premium}>5 veces por semana <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
            <MenuItem value={6} disabled={!premium}>6 veces por semana <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
            <MenuItem value={7} disabled={!premium}>7 veces por semana <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="training-duration-label">Duración del entrenamiento</InputLabel>
          <Select
            labelId="training-duration-label"
            id="training-duration"
            value={trainingDuration}
            label="Duración del entrenamiento"
            onChange={handleDurationChange}
          >
            <MenuItem value={"30 minutos"}>30 minutos</MenuItem>
            <MenuItem value={"45 minutos"}>45 minutos</MenuItem>
            <MenuItem value={"1 hora"}>1 hora</MenuItem>
            <MenuItem value={"1 hora 15 minutos"}>1 hora 15 minutos</MenuItem>
            <MenuItem value={"1 hora 30 minutos"}>1 hora 30 minutos</MenuItem>
            <MenuItem value={"1 hora 45 minutos"}>1 hora 45 minutos</MenuItem>
            <MenuItem value={"2 horas"}>2 horas</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {premium ? (
        <Box>
          <Box width="100%" display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" flexDirection="column" mt={['200px', '200px']} alignItems="center" maxWidth={['100%', '300px']} padding={[3, 4]} borderRadius={'20px'} 
              sx={{ 
                background: theme.palette.grey[200],
              }}>
              <Typography variant="body1" textAlign="center" fontWeight="bold" sx={{ marginBottom: 3 }}>
                Por ser premium, podés especificar las máquinas disponibles para todas tus planificaciones. Si aún no lo hiciste comunicate con nostros a <a href="mailto:info@wizfit.ai">info@wizfit.ai</a>
              </Typography>

            </Box>
          </Box>
        </Box>
      ) : (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" flexDirection="column" mt={['200px', '200px']} alignItems="center" maxWidth={['100%', '300px']} padding={[3, 4]} borderRadius={'20px'} 
            sx={{ 
              background: theme.palette.grey[200],
            }}>
            <Typography variant="body1" textAlign="center" fontWeight="bold" sx={{ marginBottom: 3 }}>
            ¿Necesitas especificar las máquinas que tienes disponibles? Adquiere todas las características Premium ahora.
            </Typography>
            <a href="https://wizfit.ai/pricing" target="_blank" rel="noreferrer">
              <Button variant="outlined" sx={{ paddingTop: '5px'}}>
                Ver Planes premium
              </Button>
            </a>

          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Step6;