import React, { useState, useEffect } from 'react';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { Button, Box, Typography, LinearProgress, BottomNavigation, Divider, useTheme } from '@mui/material';

// Import your step components
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import './styles.css'; // Create this CSS file for transition styles
import Footer from '../Footer';
import StepResult from './StepResult';
import StepContact from './StepContact';
import { User } from '../../contexts/userContext';
import { useNavigate } from 'react-router-dom';
import WorkoutWeekEditor from '../workweek-editor/WorkweekEditor';

type WizardProps = {
  user?: User;
};
const TOTAL = 7;
const Wizard: React.FC<WizardProps> = ({ user }) => {
  const navigate = useNavigate();
  const { currentStep, nextStep, prevStep, result, setResult } = useWizard();
  const [transition, setTransition] = useState(true);
  const theme = useTheme();
  const premium = user?.id ? true : false;

  useEffect(() => {
    if (transition) {
      const timer = setTimeout(() => {
        setTransition(false);
      }, 300); // Duration of the transition
      return () => clearTimeout(timer);
    }
  }, [transition]);

  useEffect(() => {
    if (premium && currentStep === 0) {
      nextStep();
    }
  }, [premium]);

  const handleNext = () => {
    setTransition(true);
    setTimeout(() => {
      nextStep();
    }, 300); // Duration of the transition
  };

  const handlePrev = () => {
    if (premium && currentStep === 1) {
      navigate('/workoutweeks');
      return;
    }
    setTransition(true);
    setTimeout(() => {
      prevStep();
    }, 300); // Duration of the transition
  };

  const handleRetry = () => {
    setResult(undefined);
    setTransition(true);
    setTimeout(() => {
      //prevStep();
    }, 300); // Duration of the transition
  }

  const handleGetExercise = (day: number) => {
    
  }

  // Array of step components
  const steps = [
    <Step1 key={0} onNext={handleNext} />,
    <Step2 key={1} />,
    <Step3 key={2} />,
    <Step4 key={3} />,
    <Step5 key={4} />,
    <Step6 key={5} />,
    <Step7 key={6} />,
    <StepResult key={7} />,
    <StepContact key={9} />,
  ];
  return (
    <Box>
      {currentStep > 0 && (
        <Box px={2}>
          <LinearProgress variant="determinate" value={(currentStep/TOTAL)*100} sx={{ marginBottom: 2 }} />
        </Box>
      )}
      {/* Render the current step component with transition */}
      {!result ? (
        <Box className={`step-container ${transition ? 'fade' : ''}`} sx={{ height: ['auto', 'auto'], minHeight: ['auto', '600px'] }} px={2}>
          {currentStep === 0 ? (
            <Step1 onNext={handleNext} />) : (
              steps[currentStep]
            )
          }
        </Box>
      ) : (
        premium ? (
          <WorkoutWeekEditor key={8} workoutWeek={result} setWorkoutWeek={setResult} onRetryPlanification={handleRetry}/>
        ) : (
          <StepContact />
        )
      )}
      {currentStep > 0 && currentStep < TOTAL && (
        <Box mt={[0, 4]} sx={{ zIndex: 9, bottom: 0, left: [0, 'inherit'], position: ['fixed', 'relative'], width: ['100%'], background: theme.palette.background.default }} >
          <Box display="flex" flexDirection="column" p={2} pt={0} width="100%">
            <Divider />
            <Box display="flex" justifyContent="space-between" pt={3} alignItems="center">
              <Button variant="outlined" onClick={handlePrev} disabled={currentStep === 0}>
                Volver
              </Button>
              <Typography variant="body1" color="secondary">
                Paso {currentStep + 1} de {TOTAL}
              </Typography>
              <Button variant="contained" onClick={handleNext}>
                {currentStep === TOTAL-1 ? 'Enviar' : 'Siguiente'}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Wizard;