import { AccessTime, Favorite, LineAxis, MilitaryTech, MonitorHeart, Scoreboard } from '@mui/icons-material';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext } from 'react';
import { SessionContext } from '../contexts/sessionContext';
import { formattedTime } from '../utils/times';

const TrainingSessionDataContainer = () => {
  const { trainingData } = useContext(SessionContext);
  
  return (
    <Box>
      <List>
        <ListItem>
          <ListItemIcon title="Ranking"><MilitaryTech /></ListItemIcon>
          <ListItemText>{trainingData.rank+1}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon title="Esfuerzo"><LineAxis /></ListItemIcon>
          <ListItemText>{trainingData.currentEffort[trainingData.currentEffort.length-1]?.toFixed(1)}%</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon title="Puntos"><Scoreboard /></ListItemIcon>
          <ListItemText>{trainingData.points?.toFixed(1)}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon title="Pulsaciones"><Favorite /></ListItemIcon>
          <ListItemText>
          {trainingData.currentData[trainingData.currentData.length-1]}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon title="Tiempo de entrenamiento"><AccessTime /></ListItemIcon>
          <ListItemText>{formattedTime(trainingData.currentData.length)}</ListItemText>
        </ListItem>
      </List>
    </Box>
  );
};

export default TrainingSessionDataContainer;
