import React, { ReactElement, FC, useEffect, useState, useRef, useContext } from "react";
import { Box, Button, Typography, Alert, CircularProgress, AlertTitle, Grow, IconButton, ListItemText, AccordionSummary, Accordion, AccordionDetails, Chip, List, ListItem, ListItemAvatar, ListItemButton, Badge, useTheme } from "@mui/material";
import RoomIcon from '@mui/icons-material/Room';
import api, { MultipleTraining, ProcessStatus, WorkoutWeek, WorkoutWeekDraft } from "../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import TrainingProgramDetailsComponent from "../components/TrainingDetails";
import { PageTitle } from "../components/PageTitle";
import { UserContext } from "../contexts/userContext";
import WorkoutaTableComponent from "../components/chat/WorkoutTableComponent";
import { ArrowBack, CalendarToday, ErrorOutline, HailOutlined, ViewWeek } from "@mui/icons-material";
import dayjs from "dayjs";
import WorkoutDialogEmail from "../components/WorkoutDialogEmail";
import DownloadButton from "../components/common/DownloadButton";
import UploadButton from "../components/common/UploadButton";
import { PageTitleWithBack } from "../components/common/PageTitleWithBack";
import LogoLoading from "../icons/logoLoading";
import WorkoutWeekEditor from "../components/workweek-editor/WorkweekEditor";
import { useWizard, WizardContextProvider } from "../contexts/PlanifyWizardContext";
import { stubPlanificationExercises } from "../utils/json.extract";

const loadingMessages = [
  "Cargando datos...",
  "Generando magia...",
  "Por favor, unos momentos más...",
  "Casi estamos...",
  "Preparate...",
  "Un poco de paciencia...",
  "Estamos en ello...",
  "¡No te vayas, esto valdrá la pena!",
  "Pronto tendrás tus resultados...",
  "Un momento, afinando detalles...",
  "Ajustando piezas finales...",
  "¡Aquí viene algo increíble!",
  "Desplegando sorpresas...",
  "Toma aire, ya casi...",
  "La espera valdrá la pena...",
  "Estamos poniendo todo en orden...",
  "En breve tendrás noticias...",
  "¡Cargando toda la emoción!",
  "Un segundo más, estamos en ello...",
  "Solo un momento, por favor...",
];

type MultipleTrainingsState = {
  data?: MultipleTraining,
  workoutWeekDraft?: WorkoutWeekDraft,
  status: string,
  error?: string | boolean,
}

const MultipleTrainingsDraftPage: FC<any> = (): ReactElement => {
  const containerRef = useRef<any>();
  const { id = '' } = useParams();
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const { setResult, result } = useWizard();
  const navigate = useNavigate();
  const [multipleTrainings, setMultipleTrainings] = useState<MultipleTrainingsState>({ status: "initial" });
  const [loadingMessage, setLoadingMessage] = useState(loadingMessages[Math.floor(Math.random()*100) % loadingMessages.length]);

  const getMultipleTrainings = async (id: number) => {
    setMultipleTrainings({ status: "loading" })
    const interval = setInterval(async () => {
      const multipleTrainings = await api.multipleTrainings.getById(id);
      if (multipleTrainings.data?.status === ProcessStatus.FINISHED) {
        const data = { ...multipleTrainings.data };
        const workoutWeekDraft: WorkoutWeekDraft = { 
          reasoning: '',
          motivational: '',
          user: {
            name: "",
            lastname: "",
            exercisePreference: "",
            previousExperience: "",
            mainGoal: "",
            timePerSession: "",
            previousInjuries: "",
            profession: ""
          },
          ...data.planification,
          planification: stubPlanificationExercises(data.planification?.planification || []),
       };
       console.log('workoutweek draft after', workoutWeekDraft)
        setMultipleTrainings({ status: "success", workoutWeekDraft, data: data });
        setResult(workoutWeekDraft);
        clearInterval(interval);
      } else {
        if (multipleTrainings.data?.status === ProcessStatus.ERRORED) {
          setMultipleTrainings({ status: "error", error: multipleTrainings.error });
          clearInterval(interval);
        } else {
          setLoadingMessage(loadingMessages[Math.floor(Math.random()*100) % loadingMessages.length]);
        }
      }
    }, 3000);
    
  }

  useEffect(() => {
    if (id) {
      getMultipleTrainings(parseInt(id));
    }
  }, [id]);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      ref={containerRef}
      mb={4}
    >
      
      <Box>
        {multipleTrainings.status === 'loading' && (
          <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <LogoLoading />
            <Typography variant="h4" sx={{ marginTop: 2 }} color={theme.palette.secondary.main}>
              Aguarda un instante...
            </Typography>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
              {loadingMessage}
            </Typography>
          </Box>
        )}
        {multipleTrainings.status === 'error' && (
          <Box>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Hubo un error al procesar tu solicitud. Por favor, intenta de nuevo.
            </Typography>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
              {multipleTrainings.error}
            </Typography>
          </Box>
        )}
      </Box>

      {multipleTrainings.status === 'success' && result && (
        <Box>
          <WorkoutWeekEditor workoutWeek={result} multipleTrainingId={multipleTrainings.data?.id}/>
        </Box>
      )}
    </Box>
  );
};

const MultipleTrainingsDraftPageContainer: FC<any> = (): ReactElement => {
  return (
    <WizardContextProvider>
      <MultipleTrainingsDraftPage />
    </WizardContextProvider>
  );
};

export default MultipleTrainingsDraftPageContainer;