import React, { ReactElement, FC, useState } from "react";
import { Box, Typography,Container, Tabs, Tab, AppBar } from "@mui/material";
import { PageTitle } from "../components/PageTitle";
import TabPanel from "../components/common/TabPanel";
import QuickSessionPage from "./QuickSessionPage";
import Clients from "./Clients";

const TAB_CLIENTS = 0;
const TAB_QUICK = 1;

const ClientsAndTrainingsPage: FC<any> = (): ReactElement => {
  const [tab, setTab] = useState(TAB_CLIENTS);

  const handleChange = (_: any, newNumber: number) => setTab(newNumber)
  return (
    <Box
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Clientes" />
          <Tab label="Entrenamiento rápido" />
        </Tabs>
      </Box>
      <Box pb={3}>
        <TabPanel value={tab} index={TAB_QUICK}>
          <QuickSessionPage />
        </TabPanel>
        <TabPanel value={tab} index={TAB_CLIENTS}>
          <Clients />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default ClientsAndTrainingsPage;