import React, { useState } from 'react';
import { Box, Button, Typography, TextField, InputAdornment, Alert } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import api from '../../api';
import { Link } from 'react-router-dom';

const StepContact: React.FC = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const { data, result } = useWizard();
  const [request, setRequest] = useState({ status: 'initial', error: '' });
  const [fileUrl, setFileUrl] = useState('');

  const handleSendData = async () => {
    if (!result) { return; }
    setRequest({ status: 'loading', error: '' });
    const res = await api.workoutWeeks.createFree({
      name: `Planificación de ejercicios para ${email}`,
      description: result.reasoning,
      planification: result,
      email,
      phone,
    });

    if (res.success && res.data) {  
      setRequest({ status: 'success', error: '' });
      /*
      couldnt do this because it needed to be async
      if (!res.data?.fileUrl) {
        setRequest({ status: 'error', error: 'No se pudo generar la planificación. Intenta nuevamente' });
        setTimeout(() => {
          setRequest({ status: 'initial', error: '' });
        }, 3000)
      }else {
        setFileUrl(res.data.fileUrl);
      }*/
    } else {
      setRequest({ status: 'error', error: res.error });
    }
  }

  return (
    <Box p={2}>
      <Typography variant="h2" sx={{ marginBottom: 2 }}>¡Genial! ¡Ya tenemos todo listo!</Typography>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>Tu planificación de ejercicios personalizada está lista.</Typography>
      {result && (
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          {result.reasoning}
        </Alert>
      )}

      <Typography variant="h6">Por favor, ingresa tu correo electrónico a continuación para recibir tu plan de ejercicios completo:</Typography>
    
      <Box mt={2}>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone"
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box width="100%" display="flex" alignItems="center">
        {request.status !== 'success' && (
          <Button variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleSendData} disabled={request.status === 'loading'}>
            Enviar planificación
          </Button>
        )}
        {request.status === 'error' && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            {request.error}
          </Alert>
        )}
        {request.status === 'success' && (
          <Box>
            <Alert severity="success" sx={{ marginTop: 2 }}>
              ¡Tu planificación de ejercicios ha sido enviada con éxito! Por favor, revisa tu correo electrónico.
            </Alert>
            <Box sx={{ marginTop: 2, px: 4 }} display="flex" flexDirection={['column', 'row']} alignItems="center">
              
              <Link to="/" style={{ width: '100%' }}>
                <Button variant="outlined" color="secondary" sx={{ marginTop: 2 }} fullWidth>
                  Ir al inicio
                </Button>
              </Link>
            </Box>
          </Box>
        )}
      </Box>
      
    </Box>
  );
};

export default StepContact;