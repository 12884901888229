import React, { ReactElement, FC } from "react";
import { Box, Typography,Container, CircularProgress, ListItemButton, ListItemAvatar, ListItemText } from "@mui/material";
import InsightsIcon from '@mui/icons-material/Insights';
import api, { TrainingSession } from "../api";
import { useNavigate } from "react-router-dom";

type TrainingsListProps = {
  data?: TrainingSession[],
  status: string,
  error?: string,
}

const TrainingsList: FC<TrainingsListProps> = ({ data, error, status }): ReactElement => {
  const navigate = useNavigate();

  const handleClickTraining = (trainingId: number) => () => {
    navigate(`/training_sessions/${trainingId}`);
  }
  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
    >
      <Box mt={2} pb={3}>
        {status === 'loading' && (<CircularProgress size="small" />)}
        {data?.map(t => (
          <ListItemButton key={t.id} onClick={handleClickTraining(t.id)}>
            <ListItemAvatar>
              <InsightsIcon />
            </ListItemAvatar>
            <ListItemText
              primary={t.name}
              secondary={`Programa ${t.tClass?.program?.name}`}
            />
          </ListItemButton>
        ))}
      </Box>
    </Box>
  );
};

export default TrainingsList;
