import React, { ReactElement, FC, useEffect, useState } from "react";
import { Box, Button, Container, List, ListItemButton, ListItemAvatar, ListItemText, Divider, ListItem, CircularProgress, SpeedDial, SpeedDialIcon, SpeedDialAction } from "@mui/material";
import api from "../api";
import { Link, useNavigate } from "react-router-dom";
import { formattedTime } from "../utils/times";
import { AssignmentOutlined, Person, UploadFile, Add } from "@mui/icons-material";
import SearchField from "../components/common/SearchField";

const Clients: FC<any> = (): ReactElement => {
  const [clients, setClients] = useState({ data: [], status: "initial" });
  const navigate = useNavigate();

  const getClients = async (search = "") => {
    setClients({ data: [], status: "loading" });
    const clients = await api.clients.get(search);
    setClients({ data: clients.data, status: "success" });
  };

  const handleSearch = (search: string) => getClients(search);

  useEffect(() => {
    getClients();
  }, []);


  return (
    <>
      <SearchField placeholder="Buscar cliente.." onSearch={handleSearch} />
      <List
        sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' } }}
        component="nav"
      >
        {clients.status === 'loading' && (
          <ListItem>
            <CircularProgress />
          </ListItem>
        )}
        <SpeedDial
          ariaLabel="Add client"
          sx={{ position: 'fixed', bottom: 75, right: 16 }}
          onClick={() => navigate('/clients/new')}
          icon={<SpeedDialIcon />}
        >
        </SpeedDial>
        {clients.data.map((c: any) => (
          <Link key={c.id} to={`${c.id}`}>
            <ListItemButton>
              <ListItemAvatar>
                <Person />
              </ListItemAvatar>
              <ListItemText
                primary={`${c.nick}`}
                secondary={`${c.user?.name} ${c.user?.lastname}`}
              />
              <Divider />
            </ListItemButton>
          </Link>
        ))}
      </List>
    </>
  );
};

export default Clients;