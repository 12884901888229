import React, { ReactElement, FC, useEffect, useState, useContext } from "react";
import { Box, Button,  List, ListItemButton, ListItemAvatar, ListItemText, Typography, SpeedDial, SpeedDialIcon, Divider, ListItemSecondaryAction, Alert, AlertTitle } from "@mui/material";
import api, { TrainingClass, WorkoutWeek } from "../api";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import { FitnessCenter } from "@mui/icons-material";

type WorkoutWeeksState = {
  data: WorkoutWeek[]
  error?: string
  status: string
}
const PAGE_SIZE = 20;
const WorkoutWeeks: FC<any> = (): ReactElement => {
  const [workoutWeeks, setWorkoutWeeks] = useState<Array<WorkoutWeek>>([]);
  const [request, setRequest] = useState({ page: 0, status: 'initial' });
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const isProfessor = user.role === 'PROFESSOR';
  const isClient = user.role === 'CLIENT';


  const getNextWorkoutWeeks = async () => {
    setRequest({ page: request.page + 1, status: "loading" })
    const res = await api.workoutWeeks.getAll(request.page, PAGE_SIZE);
    if (res.success) {
      setWorkoutWeeks(workoutWeeks.concat(res.data));
      if (res.data?.length && res.data?.length === PAGE_SIZE) {
        setRequest((values) => ({ ...values, status: 'success' }));
      }else {
        setRequest((values) => ({ ...values, status: 'end' }));
      }
    }
  }

  useEffect(() => {
    getNextWorkoutWeeks();
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
    >

      <PageTitle title="Planificaciones" />
      {isProfessor && user.validated && (
        <SpeedDial
          onClick={() => navigate("/workoutweeks/add")}
          ariaLabel="create workoutweek"
          sx={{ position: 'fixed', bottom: 75, right: 16 }}
          icon={<SpeedDialIcon />}
        />
      )}
      {isProfessor && !user.validated && (
        <Alert severity="info">
          <AlertTitle>Validá tu cuenta para comenzar a planificar!</AlertTitle>
        </Alert>
      )}
        {request.status === 'success' && workoutWeeks.length === 0 && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <img src="/stocks/workout_1.png" alt="" width="160px" style={{ opacity: 0.3 }}/>
            <Typography variant="h6">Comenzá a planificar!</Typography>
            <SpeedDial
              onClick={() => navigate("/workoutweeks/add")}
              ariaLabel="create workoutweek"
              icon={<SpeedDialIcon />}
            />
          </Box>
        )}
      <List
        sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' } }}
        component="nav"
      >
        {workoutWeeks.map((p: WorkoutWeek) => (
          <Link key={p.id} to={`/workoutweeks/${p.id}`}>
            <ListItemButton key={p.id} >
              <ListItemAvatar>
                <FitnessCenter />
              </ListItemAvatar>
              <ListItemText
                primary={p.name}
                secondary={`${p.days} días por semana`}
              />
              <Divider />
            </ListItemButton>
          </Link>
        ))}
      </List>

      {request.status !== 'end' && request.status !== 'loading' && (<Button variant="contained" onClick={getNextWorkoutWeeks}>Ver más</Button>)}
    </Box>
  );
};

export default WorkoutWeeks;