import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { grey } from '@mui/material/colors';
import { useTheme } from "@mui/material/styles";

type Step1Props = {
  onNext: () => void;
};
const Step1: React.FC<Step1Props> = ({ onNext }) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h4" paragraph textAlign="center">
        Bienvenido a WizFit, el asistente para la realización de rutinas automatizadas.
      </Typography>
      <Typography variant="body1" paragraph textAlign="center">
      En menos de 60 segundos tendrás una muestra de lo que podemos hacer, 
      sigue los pasos a continuación para obtener una rutina personalizada 
      para 1 día de entrenamiento. Sin costo ni datos de tarjeta de crédito.
      </Typography>
      <Button variant="contained" onClick={onNext}>
        Comenzar mi prueba gratis
      </Button>
      <Box display="flex" flexDirection="column" mt={['100px', '200px']} alignItems="center" maxWidth={['100%', '300px']} padding={[3, 4]} borderRadius={'20px'} 
        sx={{ 
          background: theme.palette.grey[200],
        }}>
        <Typography variant="body1" textAlign="center" fontWeight="bold" sx={{ marginBottom: 3 }}>
        ¿Ya conocés Wizfit? Adquiere todas las características Premium ahora.
        </Typography>
        <a href="https://wizfit.ai/pricing" target="_blank" rel="noreferrer">
          <Button variant="outlined" sx={{ paddingTop: '5px'}}>
            Ver Planes premium
          </Button>
        </a>

      </Box>
    </Box>
  );
};

export default Step1;