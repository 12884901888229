import React, { useEffect } from 'react';
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';

const Step5: React.FC = () => {
  const [previousExperience, setPreviousExperience] = React.useState<string>('');
  const [previousInjuries, setPreviousInjuries] = React.useState<string>('');
  const [previousInjuriesBool, setPreviousInjuriesBool] = React.useState<string>('');
  const { setData, data } = useWizard();

  const handleExperienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousExperience((event.target as HTMLInputElement).value);
  };

  const handlepreviousInjuriesBoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousInjuriesBool((event.target as HTMLInputElement).value);
  };


  const handlePreviousInjuriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousInjuries((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    setData({ ...data, previousExperience, previousInjuries });
  }, [previousExperience, previousInjuries]);

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        La experiencia previa es crucial para una planificación adecuada. Consideraremos cualquier lesión que hayas tenido para diseñar ejercicios apropiados.
      </Typography>
      <Typography variant="subtitle1" color="grey">
        Selecciona el nivel de entrenamiento.
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="previousExperience"
          name="previousExperience"
          value={previousExperience}
          onChange={handleExperienceChange}
          sx={{ flexDirection: 'column' }}
        >
          <FormControlLabel value="sedentario" control={<Radio />} label="Sedentario (< un mes)" />
          <FormControlLabel value="principiante" control={<Radio />} label="Principiante (1 a 6 meses)" />
          <FormControlLabel value="medio" control={<Radio />} label="Medio (6 a 12 meses)" />
          <FormControlLabel value="avanzado" control={<Radio />} label="Avanzado (más de 12 meses)" />
        </RadioGroup>
      </FormControl>
      <Typography variant="subtitle1" color="grey" sx={{ marginTop: 2 }}>
        Selecciona una opción adicional.
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="previousInjuries"
          name="previousInjuries"
          value={previousInjuriesBool}
          onChange={handlepreviousInjuriesBoolChange}
          sx={{ flexDirection: 'column' }}
        >
          <FormControlLabel value="no" control={<Radio />} label="Sin lesiones previas" />
          <FormControlLabel value="si" control={<Radio />} label="He sufrido lesiones" />
        </RadioGroup>
      </FormControl>
      {previousInjuriesBool === 'si' && (
        <TextField
          label="Por favor, proporciona más detalles"
          variant="outlined"
          value={previousInjuries}
          onChange={handlePreviousInjuriesChange}
          fullWidth
          sx={{ marginTop: 2 }}
        />
      )}
    </Box>
  );
};

export default Step5;
