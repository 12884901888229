import React from 'react';
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import WorkspacePremiumOutlined from '@mui/icons-material/WorkspacePremiumOutlined';

interface ClientGoalAndPreferencesProps {
  mainGoal: string;
  exercisePreference: string;
  premium: boolean;
  onMainGoalChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onExercisePreferenceChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  direction?: string;
}

const ClientGoalAndPreferences: React.FC<ClientGoalAndPreferencesProps> = ({
  mainGoal,
  exercisePreference,
  premium,
  onMainGoalChange,
  onExercisePreferenceChange,
  direction = 'column',
}) => {
  const dir = direction === 'row' ? 'row' : 'column'; 
  return (
    <Box display="flex" flexDirection={['column', dir]} gap={2} width="100%">
      <Box flex="1">
        <Typography variant="subtitle1" color="grey" sx={{ marginTop: 2 }}>
          Objetivo de entrenamiento
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="mainGoal"
            name="mainGoal"
            value={mainGoal}
            onChange={onMainGoalChange}
            sx={{ flexDirection: 'column' }}
          >
            <FormControlLabel value="hipertrofia" control={<Radio />} label="Hipertrofia" />
            <FormControlLabel value="fuerza" control={<Radio />} label="Fuerza" />
            <FormControlLabel value="resistencia" control={<Radio />} label="Resistencia" />
            <FormControlLabel value="bajar de peso y tonificar" control={<Radio />} label="Bajar de peso y tonificar" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box flex="2">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="subtitle1" color="grey" sx={{ marginTop: 2 }}>
            Preferencias de entrenamiento
          </Typography>
          {!premium && <WorkspacePremiumOutlined sx={{ ml: 2, mt: 1 }} />}
        </Box>

        <TextField
          multiline
          rows={4}
          placeholder='Necesito una planificación orientada a mejorar el core y la flexibilidad.'
          value={exercisePreference}
          disabled={!premium}
          fullWidth
          sx={{ marginTop: 2 }}
          onChange={onExercisePreferenceChange}
        />
      </Box>
    </Box>
  );
};

export default ClientGoalAndPreferences;