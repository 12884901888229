import React, { useContext } from 'react';
import { Container, Box, Typography, Avatar } from '@mui/material';
import { WizardContextProvider } from '../contexts/PlanifyWizardContext';
import Wizard from '../components/wizard/wizardPlanify';
import { UserContext } from '../contexts/userContext';
import { Navigate } from 'react-router-dom';

const SinglePlanifyPage = () => {

  const { user } = useContext(UserContext);

  if (user.id) {
    return <Navigate to="about" />;
  }

  return (
	<Container maxWidth="sm" sx={{ marginTop: `50px`, padding: 3, paddingLeft: 0, paddingRight: 0 }}>
    <WizardContextProvider>
      <Wizard />
    </WizardContextProvider>
	</Container>
  );
};

export default SinglePlanifyPage;