import React, { useContext, useEffect, useState } from 'react';
import api, { Band, ClientHealth } from '../api';
import { SessionContext } from './sessionContext';

type ClientBand = {
  bandId: number,
  clientId: number,
  band: Band,
};

type Client = {
  nick: string,
  bands: ClientBand[],
  clientHealths: ClientHealth[],
};
type Professor = {
  id: number,
  pin: string,
};
type Gym = {
  id?: number,
  name: string,
  logoUrl: string,
  domaRequestsLimit: number,
  fitRequestsLimit: number,
};
export type User = {
  id?: number,
  name: string,
  email: string,
  lastname: string,
  clientId: number,
  professorId: number,
  role: string,
  birthdate: string,
  client?: Client,
  professor?: Professor,
  loggedIn: boolean,
  token: string,
  gymId: number,
  gym?: Gym,
  validated: string,
  professorRole?: string,
  phone?: string,
}

const INITIAL_USER: User = {
  id: 0,
  name: '',
  lastname: '',
  loggedIn: false,
  role: '',
  token: '',
  email: '',
  clientId: 0,
  professorId: 0,
  birthdate: '',
  validated: '',
  gymId: 0,
  phone: '',
};
const UserContext = React.createContext({
  user: INITIAL_USER,
  updateUser: (user: any) => {},
  getUser: () => {},
});

const UserContextProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>(INITIAL_USER);
  const { updateTrainingSession } = useContext(SessionContext);

  const updateUser = (newValue: any) => {
    console.log("updating user", newValue)
    setUser(newValue);
  };

  const getUser = async () => {
    const profile = await api.auth.profile();
    if (!profile.data) {
      updateUser({});
      return;
    }
    const client = profile.data.clientId ? await api.clients.getById(profile.data.clientId) : null;
    const professor = profile.data.professorId ? await api.professors.getById(profile.data.professorId) : null;
    const training = profile.data.clientId ? await api.clients.getCurrentTraining(profile.data.clientId) : null;
    const gym = await api.gyms.get(profile.data.gymId);

    updateUser({
      ...profile.data,
      client: {
        ...client?.data,
        clientHealths: client?.data.clientHealths.reverse(),
        clientGoals: client?.data.clientGoals.reverse(),
      },
      professor: professor?.data,
      gym: gym?.data,
    });
    if (training?.data?.id) {
      updateTrainingSession(training?.data);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getUser()
    }
  }, [])

  return (
    <UserContext.Provider value={{ user, updateUser, getUser, }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
