import React, { ReactElement, FC, useState, useContext, useEffect } from "react";
import { Alert, Card, CardMedia, CardContent, CardActions, CardActionArea, AlertTitle, Box, Button, Chip, Divider, FormControl, List, ListItem, ListItemIcon, ListItemText, Stack, TextField, Typography, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import api, { ProgramStep, Room } from "../api";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import { formatToLongDate } from "../utils/dates";
import { Accessibility, MonitorHeart } from "@mui/icons-material";
import UserFormContainer from "../containers/UserFormContainer";

const Profile: FC<any> = (): ReactElement => {
  const navigate = useNavigate();
  const [data, setData] = useState({ bandCode: '' });
  const { user, getUser } = useContext(UserContext);
  const [update, setUpdate] = useState({ status: 'initial', error: '' });
  const isProfessor = user.role === 'PROFESSOR'
  const handleSignOUt = () => {
    localStorage.removeItem('token');
    navigate('/');
    window.location.reload();
  }
  const handleSave = async () => {
    if (!user?.clientId) return;
    setUpdate({ status: 'loading', error: '' })
    const res = await api.clients.addBand(user?.clientId, data)

    if (res.success) {
      setUpdate({ status: 'success', error: ''})
      setData({ bandCode: '' })
      getUser();
      setTimeout(() => setUpdate({ status: 'initial', error: '' }), 3000);
    }else {
      setUpdate({ status: 'error', error: res.error });
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  const lastBandCode = user.client?.bands?.length  ? user.client?.bands[user.client?.bands.length-1].band.code : '';

  if (!user?.name) {
    return (
      <Box>
        <Skeleton variant="rectangular" width="100%" height={50}  sx={{ mb: 2 }} animation="wave"/>
        <Skeleton variant="rectangular" width={300} height={300} sx={{ mb: 2 }} animation="wave" />
      </Box>
    )
  }
  return (
    <Box pb={3}>
      <PageTitle title="Mi perfil" />
      <Box mt={2} m={1}>
        Bienvenido {user.name}!
      </Box>
      {!isProfessor ? (
        <Box mt={2} m={1} display="flex" flexDirection="column">
          <UserFormContainer />
          <Divider />
          <Box>
            <Typography variant="h4">Datos de salud</Typography>
            <Box>
              {user.client?.clientHealths?.map(ch => (
                <Box key={`${ch.createdAt}`}>
                  <Stack direction="row" spacing={1}>
                    <Chip label={formatToLongDate(ch.createdAt)} color="primary" />
                  </Stack>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <Accessibility />
                      </ListItemIcon>
                      <ListItemText primary={`${ch.weight} kg. ${ch.height} cm`} />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <MonitorHeart />
                      </ListItemIcon>
                      <ListItemText primary={`${ch.basal} basal. ${ch.max} Máximo esfuerzo`} />
                    </ListItem>
                  </List>
                </Box>
              ))}
            </Box>
          </Box>
          <Divider />
          <Typography variant="h4">Dispositivos</Typography>
          <Box>
            <FormControl>
              <TextField value={data.bandCode} label="N° banda" onChange={(evt) => setData((data) => ({ ...data, bandCode: evt.target.value }))} />
            </FormControl>
            <Box mt={1}>
              {update.status === 'error' && (
                <Alert severity="error">
                  <AlertTitle>{update.error}</AlertTitle>
                </Alert>
              )}
              {update.status === 'success' && (
                <Alert severity="success">
                  <AlertTitle>Banda asociada</AlertTitle>
                </Alert>
              )}
              <FormControl>
                <Button variant="contained" disabled={update.status === 'loading'} onClick={handleSave}>
                  Guardar
                </Button>
              </FormControl>
            </Box>
            {user.client?.bands?.length ? (
              <Box mt={1}>
                <Typography variant="body1">
                  <b>Código de banda:&nbsp;</b>{lastBandCode}
                </Typography>
              </Box>
            ) : (<></>)}
          </Box>
        </Box>
      ) : (
        <Box m={2}>
          {user.gym && (
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  loading="lazy"
                  height="150"
                  image={user.gym.logoUrl || '/logo_2_out.png'}
                  sx={{ objectFit: 'contain' }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {user.gym.name}
                  </Typography>
                  
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary" disabled>
                  Consultas restantes: {user.gym.fitRequestsLimit}
                </Button>
              </CardActions>
            </Card>
          )}
        </Box>
      )}
      <Divider />
      <Box mt={3} m={1}>
        <Button onClick={handleSignOUt} variant="contained">
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default Profile;