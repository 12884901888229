
import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import { CheckOutlined, SendTimeExtensionRounded } from '@mui/icons-material';
import api, { PlanificationDay, WorkoutWeekCreate, WorkoutWeekDraft } from '../../api';
import { useNavigate } from 'react-router-dom';
import { create } from 'domain';
import { ExercisesByDay } from './WorkweekEditor';

type Props = {
  name: string;
  setName: (name: string) => void;
  workoutWeek: WorkoutWeekDraft;
  onRetryPlanification?: () => void;
  clientId?: number;
  exercises:{ exercisesByDay: ExercisesByDay[], exercisesIdByDay: number[][] };
  multipleTrainingId?:number;
};
const WorkweekTitle = ({ name, setName, workoutWeek, exercises, clientId, onRetryPlanification, multipleTrainingId }: Props) => {
  const [request, setRequest] = useState({ status: 'initial', error: '' });
  const [localName, setLocalName] = useState(name);
  const navigate = useNavigate();


  // Debounce function to delay the update
  const debouncedSetName = debounce((value: string) => {
    setName(value);
    // Update the value at wizrd here
    // Example: updateWizrd(value);
  }, 500); // 500ms delay

  useEffect(() => {
    debouncedSetName(localName);
    // Cleanup function to cancel debounce on unmount
    return () => {
      debouncedSetName.cancel();
    };
  }, [localName]);

  const handleConfirmPlan = async () => {
    setRequest({
      status: 'loading',
      error: '',
    });
    const updatedPlanification = Object.values(exercises.exercisesIdByDay).map((exerciseIdsPerDay, index) => {
      return {
        day: exercises.exercisesByDay[index].day,
        exercises: exerciseIdsPerDay.map((exerciseId) => exercises.exercisesByDay[index].exercises[exerciseId]),
      } as unknown as PlanificationDay;
      //return exercises.exercisesByDay[day].map((exercise, index) => exercise);
    });
    const planification = { ...workoutWeek, planification: updatedPlanification };
    console.log('workout to create', planification)
    const workoutWeekCreate: WorkoutWeekCreate = {
      name: localName,
      planification: { ...workoutWeek, planification: updatedPlanification },
      description: workoutWeek.reasoning,
      multipleTrainingId: multipleTrainingId,
    };
    if (clientId) {
      createWorkoutWeekToClient(clientId, workoutWeekCreate);
    } else {
      createWorkoutWeek(workoutWeekCreate);
    }
  }

  const createWorkoutWeekToClient = async (clientId: number, workoutWeekCreate: WorkoutWeekCreate) => {
    const res = await api.clients.createWorkoutWeek(clientId, workoutWeekCreate);
    if (res.success) {
      setRequest({
        status: 'initial',
        error: '',
      })
      navigate('/workoutweeks');
    } else {
      setRequest({
        error: res.error,
        status: 'error',
      })
    }
  };

  const createWorkoutWeek = async (workoutWeekCreate: WorkoutWeekCreate) => {
    const res = await api.workoutWeeks.create(workoutWeekCreate);
    if (res.success) {
      setRequest({
        status: 'initial',
        error: '',
      })
      navigate('/workoutweeks');
    } else {
      setRequest({
        error: res.error,
        status: 'error',
      })
    }
  }


  return (
    <Box>
      <Card sx={{ mb: 2, mt: 2 }}>
        <CardContent>
          <TextField
            label="Nombre de la planificación"
            value={localName}
            fullWidth
            sx={{ marginTop: 2 }}
            onChange={(e) => setLocalName(e.target.value)}
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            {workoutWeek.reasoning}
          </Typography>
        </CardContent>
      </Card>
      <Box display="flex" alignItems="flex-end">
        {onRetryPlanification && (
          <Button disabled={request.status === 'loading'} variant="contained" color="secondary" onClick={onRetryPlanification} startIcon={<SendTimeExtensionRounded />}>
            Volver a planificar
          </Button>
        )}
        <Button disabled={request.status === 'loading'} variant="contained" color="primary" sx={{ ml: 2 }} startIcon={<CheckOutlined />} onClick={handleConfirmPlan} >
          Guardar planificación
        </Button> 
      </Box>
    </Box>
  );
};

export default WorkweekTitle;