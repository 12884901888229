import React, { ReactElement, FC, useEffect, useContext, useState } from "react";
import { Alert, Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Dialog, DialogTitle, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Skeleton, Stack, Typography } from "@mui/material";
import api, { Client, TrainingSession } from "../api";
import { UserContext } from "../contexts/userContext";
import { PageTitle } from "../components/PageTitle";
import TrainingsList from "../components/TrainingsList";
import TrainingsListContainer from "../containers/TrainingsListContainer";
import { useNavigate, useParams } from "react-router-dom";
import { formatBirthday, formatToLongDate } from "../utils/dates";
import { Accessibility, EditOutlined, MonitorHeart, PhoneOutlined, WorkOutlineOutlined } from "@mui/icons-material";
import ClientGoalsContainer from "../components/client/goals-list";
import GoalCard from "../components/client/goal-card";
import HealthCard from "../components/client/health-card";
import BodyTypeCard from "../components/client/body-type-card";
import Transition from "../components/common/Transition";
import EditForm, { ClientUpdate } from "../components/client/edit-form";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers";
import PlanificationCard from "../components/client/planification-card";
import LogoGradient from "../icons/logoGradient";

type ClientState = {
  data?: Client,
  error?: string,
  status: string,
};

const errors: { [key: string]: string } = {
  'invalid_goal': 'El cliente tiene el objetivo sin completar.',
}

const ClientView: FC<any> = (): ReactElement => {
  const { user } = useContext(UserContext);
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const [clientEditRequest, setClientEditRequest] = useState({ status: 'initial', error: '' });
  const [newPlanificationRequest, setNewPlanificationRequest] = useState({ status: 'initial', error: '' });
  const [client, setClient] = useState<ClientState>({ status: 'initial' });
  const [showForm, setShowForm] = useState(false);

  const isMobile = window.innerWidth < 600;
  const isCreatingUser = id === 'new';

  const handleOnCancel = () => {
    if (isCreatingUser) {
      navigate('/clients');
    } else {
      setShowForm(false);
    }
  }
  const getClient = async (clientId: number) => {
    setClient({ status: 'loading' });
    //await new Promise((resolve) => setTimeout(resolve, 1000));
    const res = await api.clients.getById(clientId);

    if (res.success) {
      setClient({ status: 'success', data: res.data });
    }else {
      setClient({ error: res.error, status: 'error' });
    }
  }
  const handleUpdateClient = async (data: ClientUpdate) => {
    if (!id) return;
    if (!client.data) return;
    setClientEditRequest({ status: 'loading', error: '' });
    const res = await (id !== 'new' ? api.clients.update(parseInt(id), data) : api.clients.create(data));
    if (res.success) {
      setClientEditRequest({ status: 'success', error: '' });
      if (isCreatingUser) {
        navigate(`/clients/${res.data?.id}`, { replace: true });
      } else {
        getClient(parseInt(id));
      }
      setShowForm(false);
    } else {
      setClientEditRequest({ status: 'error', error: res.error || 'Error updating' });
    }
  }
  const handleNewPlanification = async () => {
    if (!client.data?.user?.document) {
      setNewPlanificationRequest({ status: 'error', error: 'Documento de identidad incompleto' });
      return;
    }
    setNewPlanificationRequest({ status: 'loading', error: '' });
    const res = await api.clients.generatePlanification(parseInt(id));
    if (res.success) {
      navigate(`/multiple-trainings-draft/${res.data?.id}`);
    } else {
      setNewPlanificationRequest({ status: 'error', error: res.error || 'Error creating planification' });
    }
  };

  useEffect(() => {
    console.log("id", id)
    if (id && id !== 'new') {
      getClient(parseInt(id));
    }
    if (id === 'new') {
      setClient({ status: 'success', data: {
        id: 0,
        nick: '',
        user: {
          name: '',
          lastname: '',
          email: '', document: '', phone: '', gender: 'MASCULINE'
        },
        userId: 0, bodyType: '', clientGoals: [], clientHealths: [], profession: '' }
      });
      setShowForm(true);
    }
  }, [id]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
    >
      {client.status === 'loading' ? (
        <Box>
          <Skeleton variant="rectangular" width="100%" height={50} sx={{ mb: 2 }} animation="wave" />
          <Skeleton variant="rectangular" width="100%" height={200} animation="wave"/>
        </Box>
      ) : (
        <>
          <PageTitle title={client.data?.nick} />
          <Box width={'100%'} display="flex" mb={3} p={2}>
            <Box flex="2" display="flex" flexDirection={['column', 'row']} gap={4}>
              
              <Box>
                <Avatar src="/avatar-person.svg" sizes="100px"/>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ textTransform: 'uppercase' }}>{`${client.data?.user?.name} ${client.data?.user?.lastname}`} </Typography>
                <Typography variant="subtitle2">{`${client.data?.user?.email}`} </Typography>
                <Typography variant="subtitle2">{`${client.data?.user?.document}`} </Typography>
                <Box display="flex" mb={1}>
                  <PhoneOutlined sx={{ mr: 2}} />
                  <Typography variant="body1">{`${client.data?.user?.phone || '-'}`} </Typography>
                </Box>
                <Box display="flex" mb={1}>
                  <CalendarIcon sx={{ mr: 2}} />
                  <Typography variant="body1">{`${formatBirthday(client.data?.user?.birthdate)}`} </Typography>
                </Box>

                <Box display="flex" mb={1}>
                  <WorkOutlineOutlined sx={{ mr: 2}} />
                  <Typography variant="body1">{`${client.data?.profession || '-'}`} </Typography>
                </Box>
                <Box mt={2}>
                  <Button color="primary" onClick={() => setShowForm(true)} startIcon={<EditOutlined color="primary" />}>
                    
                    Editar
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box flex="1">
              <Box>
                <BodyTypeCard bodyType={client.data?.bodyType || ''} gender={client.data?.user?.gender} />
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box display="flex" flexDirection={['column', 'row']} mt={2} mx={[0, 1]} mb={2}>
            <Box mt={2} mx={[0, 1]} flex="1" display="flex" flexDirection="column">
              <Typography variant="h4">Objetivo</Typography>
              <GoalCard goal={client.data?.clientGoals[0]} clientId={client.data?.id} />
            </Box>
            <Box mt={2} mx={[0, 1]} flex="1" display="flex" flexDirection="column">
              <Typography variant="h4">Salud</Typography>
              <HealthCard health={client.data?.clientHealths[0]} clientId={client.data?.id} />
            </Box>
          </Box>
          <Divider />

          <Box display="flex" flexDirection={['column', 'row']} mt={2} mx={[0, 1]}>
            <Box display="flex" mt={2} mx={[0, 1]} flexDirection="column" width="100%">
              <Typography variant="h4">Planificación actual</Typography>
              <PlanificationCard clientId={client.data?.id} />

              {newPlanificationRequest.status === 'error' && (
                <Alert sx={{ mt: 2 }} severity="error">{errors[newPlanificationRequest.error] || newPlanificationRequest.error}</Alert>
              )}
              <Box mt={3} width="100%" textAlign="center" mb={3}>
                <Button variant="outlined" onClick={handleNewPlanification} disabled={newPlanificationRequest.status === 'loading' || isCreatingUser}>
                  <LogoGradient />
                  <Typography variant="h6">Nueva planificación</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          
        </>
      )}
      
      <Dialog
        open={showForm}
        fullScreen={isMobile}
        TransitionComponent={Transition}
        PaperProps={{ sx: { width: ['100%', '800px'], maxWidth: '100%' } }}
      >
        <DialogTitle>{isCreatingUser ? 'Crear Cliente' : 'Actualizar Cliente'}</DialogTitle>
        <EditForm onSave={handleUpdateClient} onCancel={handleOnCancel} request={clientEditRequest} client={client.data} />
      </Dialog>
    </Box>
  );
};

export default ClientView;