import React, { ReactElement, FC, useEffect, useState } from "react";
import { Box, Button, Container, List, ListItemButton, ListItemAvatar, ListItemText, Typography, SpeedDial, SpeedDialIcon, AccordionSummary, Accordion, AccordionDetails } from "@mui/material";
import api from "../api";
import { Link, useNavigate } from "react-router-dom";
import { formattedTime } from "../utils/times";
import { AssignmentOutlined } from "@mui/icons-material";
import { PageTitle } from "../components/PageTitle";
import TrainingProgramDetailsComponent from "../components/TrainingDetails";

const Programs: FC<any> = (): ReactElement => {
  const [programs, setPrograms] = useState({ data: [], status: "initial" });
  const navigate = useNavigate();

  const getPrograms = async () => {
    setPrograms({ data: [], status: "loading" })
    const programs = await api.programs.get();
    setPrograms({ data: programs.data, status: "success" });
  }

  useEffect(() => {
    getPrograms();
  }, []);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
    >
      <PageTitle title="Programas" />
      <SpeedDial
        ariaLabel="Add program"
        sx={{ position: 'fixed', bottom: 75, right: 16 }}
        icon={<SpeedDialIcon onClick={() => navigate("/programs/add")}/>}
      />
      <List
        sx={{ bgcolor: 'background.paper', paddingBottom: '80px' }}
        component="nav"
      >
        {programs.data.map((p: any) => (
          <Accordion TransitionProps={{ unmountOnExit: true }} key={p.id} style={{ boxShadow: 'none' }}>
            <AccordionSummary>
              <ListItemText
                primary={p.name}
                secondary={`${p.steps?.length} etapas. Tiempo total: ${formattedTime(p.totalTime)}`}
              />
            </AccordionSummary>
            <AccordionDetails>
              <TrainingProgramDetailsComponent program={p} />
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Box>
  );
};

export default Programs;