import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import api from '../../api';
import { extractWorkoutWeekFromText } from '../../utils/json.extract';
import LogoLoading from '../../icons/logoLoading';

const loadingMessages = [
  "Cargando datos...",
  "Generando magia...",
  "Por favor, unos momentos más...",
  "Casi estamos...",
  "Preparate...",
  "Un poco de paciencia...",
  "Estamos en ello...",
  "¡No te vayas, esto valdrá la pena!",
  "Pronto tendrás tus resultados...",
  "Un momento, afinando detalles...",
  "Ajustando piezas finales...",
  "¡Aquí viene algo increíble!",
  "Desplegando sorpresas...",
  "Toma aire, ya casi...",
  "La espera valdrá la pena...",
  "Estamos poniendo todo en orden...",
  "En breve tendrás noticias...",
  "¡Cargando toda la emoción!",
  "Un segundo más, estamos en ello...",
  "Solo un momento, por favor...",
];

const StepResult: React.FC = () => {
  const { setResult, data } = useWizard();
  const theme = useTheme();
  const [loadingMessage, setLoadingMessage] = useState(loadingMessages[Math.floor(Math.random()*100) % loadingMessages.length]);
  const [result, setResultInternal] = useState<any>({ error: false, data: {}, loading: true });
  const [interval, setIntervalObject] = useState<any>(null);
  
  const handleSendData = async () => {
    loadingInterval();

    try {
      const res = await api.openai.planify(JSON.stringify(data));
      if (res.success) {
        const workoutWeek = res.data;
        if (!workoutWeek) { 
          setResultInternal({ error: "No se pudo generar la planificación", data: {} });
        }else {
          setResult(workoutWeek);
        }
      } else {
        setResultInternal({ error: res.data, data: {} });
      }
    }catch (error) {
      setResultInternal({ error: 'Hay un inconveniente con el asistente. Disculpa las molestias, ponte en contacto con info@doma.fit', data: {} });
    }
    clearInterval(interval)
    setIntervalObject(null);
  };
  const loadingInterval = () => {
    const interval = setInterval(() => {
      setLoadingMessage(prevMessage => {
        const currentIndex = loadingMessages.indexOf(prevMessage);
        const nextIndex = (currentIndex + 1) % loadingMessages.length;
        return loadingMessages[nextIndex];
      });
    }, 3000);
    setIntervalObject(interval);
  } 


  useEffect(() => {
    handleSendData()
  }, []);
  

  return (
    <Box>
      {result.loading && (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          <LogoLoading />
          <Typography variant="h4" sx={{ marginTop: 2 }} color={theme.palette.secondary.main}>
            Aguarda un instante...
          </Typography>
          <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
            {loadingMessage}
          </Typography>
        </Box>
      )}
      {result.error && (
        <Box>
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Hubo un error al procesar tu solicitud. Por favor, intenta de nuevo.
          </Typography>
          <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
            {result.error}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default StepResult;